import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  callGet,
  callPut,
  callPost,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  Settings,
  EnvConfig,
  ParamsRole,
  RespStatus,
  SettingForm,
} from '@real-estate-portal/interfaces';
import { Roles } from '@real-estate-portal/enums';

export const settingPost = createAsyncThunk(
  'settings/create',
  async (payload: SettingForm, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, SettingForm>(
        getHttpEndpoint(payload.role).settingsPost ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const settingPut = createAsyncThunk(
  'settings/update',
  async (payload: SettingForm, thunkAPI) => {
    try {
      const { uuid, ...rest } = payload;
      const { data } = await callPut<RespStatus, SettingForm>(
        getHttpEndpoint(payload.role, `/${uuid}`).settingsPut ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const settingGet = createAsyncThunk(
  'settings/fetch',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callGet<Settings>(
        getHttpEndpoint(role, `/${id}`).settingsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const settingEnvPut = createAsyncThunk(
  'settings/update-env',
  async (payload: EnvConfig, thunkAPI) => {
    try {
      const { data } = await callPut<RespStatus, EnvConfig>(
        getHttpEndpoint(payload.role).settingsEnvPut ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const settingEnvGet = createAsyncThunk(
  'settings/fetch-env-variables',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<EnvConfig>(
        getHttpEndpoint(role).settingsEnvGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
