import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SnackbarPayload,
  SnackbarProps as Snackbar,
} from '@real-estate-portal/interfaces';
import { SnackbarAction } from '@real-estate-portal/enums';
import { getSnackbarProps } from '@real-estate-portal/utils';
import { snackbarDefaultValues } from '@real-estate-portal/constants';

interface PopupState {
  snackbar: Snackbar;
}

export const initialState: PopupState = {
  snackbar: snackbarDefaultValues,
};

interface PopupSlice {
  popup: PopupState;
}

export const popupSlice = createSlice({
  initialState,
  name: 'popups',
  reducers: {
    enableSnackbar: (state, { payload }: PayloadAction<SnackbarPayload>) => {
      if (payload === SnackbarAction.SAVE_SUCCESS) {
        state.snackbar = getSnackbarProps(true, 'Data saved!!', 'success');
      }

      if (payload === SnackbarAction.DELETE_SUCCESS) {
        state.snackbar = getSnackbarProps(true, 'Data deleted!!', 'success');
      }

      if (payload === SnackbarAction.DELETE_FAIL) {
        state.snackbar = getSnackbarProps(true, 'Deletion failed!!', 'error');
      }

      if (payload === SnackbarAction.SAVE_FAIL) {
        state.snackbar = getSnackbarProps(true, 'Failed to save!!', 'error');
      }
    },

    disableSnackbar: (state) => {
      state.snackbar = snackbarDefaultValues;
    },
  },
  extraReducers: (builder) => {
    /* ? Nothing to implement */
  },
});

export const popupReducer = popupSlice.reducer;
export const { enableSnackbar, disableSnackbar } = popupSlice.actions;

export const selectSnackbar = (state: PopupSlice): Snackbar =>
  state.popup.snackbar;
