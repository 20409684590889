import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';
import {
  AdminReports,
  SearchApiResponse,
  TenantReport,
} from '@real-estate-portal/interfaces';

import { reportsGet, reportGet, searchGet } from './actions';

interface ReportsState {
  adminReports: AdminReports;
  tenantReports: TenantReport;
  searchResults: SearchApiResponse;

  searchGetStatus: ProcessingStatus;
  reportTenantGetStatus: ProcessingStatus;
  reportsAdminGetStatus: ProcessingStatus;
}

export const initialState: ReportsState = {
  adminReports: {} as AdminReports,
  tenantReports: {} as TenantReport,
  searchResults: {} as SearchApiResponse,

  searchGetStatus: ProcessingStatus.IDLE,
  reportTenantGetStatus: ProcessingStatus.IDLE,
  reportsAdminGetStatus: ProcessingStatus.IDLE,
};

interface ReportsSlice {
  reports: ReportsState;
}

export const reportsSlice = createSlice({
  initialState,
  reducers: {},
  name: 'reports',
  extraReducers: (builder) => {
    builder.addCase(reportsGet.pending, (state) => {
      state.reportsAdminGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(reportsGet.fulfilled, (state, action) => {
      state.reportsAdminGetStatus = ProcessingStatus.SUCCEEDED;
      state.adminReports = action.payload;
    });
    builder.addCase(reportsGet.rejected, (state) => {
      state.reportsAdminGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(reportGet.pending, (state) => {
      state.reportTenantGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(reportGet.fulfilled, (state, action) => {
      state.reportTenantGetStatus = ProcessingStatus.SUCCEEDED;
      state.tenantReports = action.payload;
    });
    builder.addCase(reportGet.rejected, (state) => {
      state.reportTenantGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(searchGet.pending, (state) => {
      state.searchGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(searchGet.fulfilled, (state, action) => {
      state.searchGetStatus = ProcessingStatus.SUCCEEDED;
      state.searchResults = action.payload;
    });
    builder.addCase(searchGet.rejected, (state) => {
      state.searchGetStatus = ProcessingStatus.FAILED;
    });
  },
});

export const reportsReducer = reportsSlice.reducer;

export const selectAdminGetStatus = (state: ReportsSlice): ProcessingStatus =>
  state.reports.reportsAdminGetStatus;

export const selectSearchGetStatus = (state: ReportsSlice): ProcessingStatus =>
  state.reports.searchGetStatus;

export const selectTenantGetStatus = (state: ReportsSlice): ProcessingStatus =>
  state.reports.reportTenantGetStatus;

export const selectAdminReports = (state: ReportsSlice): AdminReports =>
  state.reports.adminReports;

export const selectTenantReports = (state: ReportsSlice): TenantReport =>
  state.reports.tenantReports;

export const selectSearchResults = (state: ReportsSlice): SearchApiResponse =>
  state.reports.searchResults;
