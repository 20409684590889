import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Lazy } from '@real-estate-portal/ui';

import ProtectedRoute from './PrivateRoute';
import { getPaths } from '@real-estate-portal/utils';
const Login = React.lazy(() => import('../pages/login/Login'));
const Dashboard = React.lazy(() => import('../pages/dashboard/AdminDashboard'));

function RouterComponent(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route exact component={Lazy(Login)} path={getPaths().baseUrl} />
        <Route exact component={Lazy(Login)} path={getPaths().login} />

        <ProtectedRoute
          exact
          component={Lazy(Dashboard)}
          path={getPaths().dashboard_tenant}
        />
      </Switch>
    </Router>
  );
}

export default RouterComponent;
