import Cookie from 'js-cookie';

export function deleteCookie(key: string): void {
  Cookie.remove(key, { domain: process.env.NX_COOKIE_TARGET_DOMAIN });
}

export function getCookie(key: string): string | undefined {
  return Cookie.get(key);
}

export function storeCookie(key: string, encData: string): void {
  Cookie.set(key, encData, {
    domain: process.env.NX_COOKIE_TARGET_DOMAIN,
  });
}
