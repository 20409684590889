import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';
import { LoginResponse } from '@real-estate-portal/interfaces';

import {
  loginPost,
  registerPost,
  resetPasswordPost,
  forgotPasswordPost,
} from './actions';

interface AuthenticationState {
  tempPassword: string | undefined;
  loginPostStatus: ProcessingStatus;
  authData: LoginResponse | undefined;
  registerPostStatus: ProcessingStatus;
  resetPasswordStatus: ProcessingStatus;
}

export const initialState: AuthenticationState = {
  authData: undefined,
  tempPassword: undefined,
  loginPostStatus: ProcessingStatus.IDLE,
  registerPostStatus: ProcessingStatus.IDLE,
  resetPasswordStatus: ProcessingStatus.IDLE,
};

interface AuthSlice {
  auth: AuthenticationState;
}

export const authenticationSlice = createSlice({
  initialState,
  reducers: {
    resetState: (state) => {
      state.tempPassword = undefined;
      state.loginPostStatus = ProcessingStatus.IDLE;
      state.registerPostStatus = ProcessingStatus.IDLE;
      state.resetPasswordStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'authentication',
  extraReducers: (builder) => {
    builder.addCase(loginPost.pending, (state) => {
      state.loginPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(loginPost.fulfilled, (state, { payload }) => {
      state.loginPostStatus = ProcessingStatus.SUCCEEDED;
      state.authData = payload;
    });
    builder.addCase(loginPost.rejected, (state) => {
      state.loginPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(registerPost.pending, (state) => {
      state.registerPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(registerPost.fulfilled, (state, { payload }) => {
      state.tempPassword = payload ? payload : undefined;
      state.registerPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(registerPost.rejected, (state) => {
      state.registerPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(resetPasswordPost.pending, (state) => {
      state.resetPasswordStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(resetPasswordPost.fulfilled, (state, { payload }) => {
      state.resetPasswordStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(resetPasswordPost.rejected, (state) => {
      state.resetPasswordStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(forgotPasswordPost.pending, (state) => {
      state.resetPasswordStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(forgotPasswordPost.fulfilled, (state, { payload }) => {
      state.resetPasswordStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(forgotPasswordPost.rejected, (state) => {
      state.resetPasswordStatus = ProcessingStatus.FAILED;
    });
  },
});

export const { resetState } = authenticationSlice.actions;
export const authDataReducer = authenticationSlice.reducer;

export const selectLoginStatus = (state: AuthSlice): ProcessingStatus =>
  state.auth.loginPostStatus;

export const selectResetPasswordStatus = (state: AuthSlice): ProcessingStatus =>
  state.auth.resetPasswordStatus;

export const selectRegisterStatus = (state: AuthSlice): ProcessingStatus =>
  state.auth.registerPostStatus;

export const selectAuthData = (state: AuthSlice): LoginResponse | undefined =>
  state.auth.authData;

export const selectTempPass = (state: AuthSlice): string | undefined =>
  state.auth.tempPassword;
