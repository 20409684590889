import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

interface Props {
  name: string;
  isOccupied: boolean;
  unitId?: string;
  subText?: string;
  onClick: (unitId?: string) => void;
}

export function PropertyUnit(props: Props): JSX.Element {
  const { name, subText, isOccupied, onClick, unitId } = props;
  const classes = useStyles({ isOccupied });

  const handleClick = (): void => {
    onClick(unitId);
  };

  return (
    <div className={classes.root} onClick={handleClick}>
      <Typography variant="subtitle2">
        {name}
        {subText && <span className={classes.subText}>{subText}</span>}
      </Typography>
    </div>
  );
}
