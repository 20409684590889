import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';
import { CustomButton } from '../../form/';
import { CustomModal } from './CustomModal';

interface Props {
  text: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;

  modalWidth?: number;
}

export function DeleteAlert(props: Props): JSX.Element {
  const {
    text,
    isOpen,
    modalWidth,
    onCancel: handleCancel,
    onConfirm: handleConfirm,
  } = props;
  const classes = useStyles({ modalWidth });

  return (
    <CustomModal isOpen={isOpen} onClick={handleCancel}>
      <Typography className={classes.text}>{text}</Typography>
      <div className={classes.ctaContainer}>
        <CustomButton
          text="Confirm"
          theme="primary"
          id="confirm-del-property-btn"
          onClick={handleConfirm}
        />
        <CustomButton
          text="Abort"
          theme="secondary"
          id="cancel-del-property-btn"
          className={classes.deleteBtn}
          onClick={handleCancel}
        />
      </div>
    </CustomModal>
  );
}
