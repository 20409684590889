import axios, { AxiosResponse, ResponseType } from 'axios';

export const api = axios.create({
  baseURL: process.env.NX_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export function callGet<T>(
  url: string,
  responseType: ResponseType = 'json'
): Promise<AxiosResponse<T>> {
  return api.get<T>(url, { responseType });
}

export function callPost<T, P>(
  url: string,
  body?: P
): Promise<AxiosResponse<T>> {
  return api.post<T>(url, body);
}

/* TODO:  Activate when needed
export function callPatch<T, P>(
  url: string,
  body: P
): Promise<AxiosResponse<T>> {
  return api.patch<T>(url, body);
}*/

export function callPut<T, P>(url: string, body: P): Promise<AxiosResponse<T>> {
  return api.put<T>(url, body);
}

export function callDelete<T>(url: string): Promise<AxiosResponse<T>> {
  return api.delete<T>(url);
}
