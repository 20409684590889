// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

interface Environment {
  sentryDns: string;
  sentryRate: string;
  production: boolean;
  authTokenName: string;
  apiFileUploadKey: string;
}

export const environment: Environment = {
  production: false,
  sentryDns: process.env.NX_SENTRY_DNS ?? '',
  sentryRate: process.env.NX_SENTRY_RATE ?? '0',
  authTokenName: process.env.NX_TOKEN_NAME ?? '',
  apiFileUploadKey: process.env.NX_API_FILE_UPLOAD_KEY ?? '',
};
