import React, { useCallback } from 'react';
import { Text, View } from '@react-pdf/renderer';

import {
  SumOption,
  OptionType,
  Transaction,
} from '@real-estate-portal/interfaces';
import { primaryColors } from '@real-estate-portal/constants';
import { formatDigit, formatTime, getStatus } from '@real-estate-portal/utils';

import styles from '../templates/styles';
import PdfTemplate from '../templates/PdfTemplate';

interface Props {
  logo: string;
  totals: SumOption[];
  tenantInfo: OptionType[];
  cycle: string | undefined;
  companyInfo: OptionType[];

  transactions?: Transaction[] | undefined;
}

export function TransactionsPdf(props: Props): JSX.Element {
  const { logo, cycle, totals, transactions, tenantInfo, companyInfo } = props;

  const isEven = useCallback((n: number): boolean => {
    return n % 2 === 0;
  }, []);

  return (
    <PdfTemplate
      logo={logo}
      cycle={cycle}
      totals={totals}
      tenantInfo={tenantInfo}
      companyInfo={companyInfo}
      reportType="Transactions"
    >
      <View style={styles.table}>
        {transactions && (
          <View style={styles.row}>
            <View style={[styles.cell, { width: '10%' }]}>
              <Text style={styles.cellText}>Service</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Charges</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Method</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Amount</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Trans. Code</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Balance</Text>
            </View>

            <View style={[styles.cell, { width: '20%' }]}>
              <Text style={styles.cellText}>Created</Text>
            </View>
          </View>
        )}

        {transactions &&
          transactions.map((trans, key) => (
            <View
              key={key}
              style={isEven(key) ? styles.coloredRowTrans : styles.rowTrans}
            >
              <View style={styles.transFlex}>
                <View style={[styles.cell, { width: '10%' }]}>
                  <Text style={styles.transText}>{trans.serviceType}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.transText}>
                    {formatDigit(trans.serviceTypeAmounnt)}
                  </Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.transText}>{trans.paymentMethod}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.transText}>
                    {formatDigit(trans.amount)}
                  </Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.transText}>{trans.transactioncode}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.transText}>
                    {formatDigit(trans.balance)}
                  </Text>
                </View>

                <View style={[styles.cell, { width: '20%' }]}>
                  <Text style={styles.transText}>
                    {formatTime(trans.created_at)}
                  </Text>
                </View>
              </View>

              {/* {trans.note && trans.note !== 'n/a' && (
                <View style={styles.transNote}>
                  <Text
                    style={{
                      fontSize: '9px',
                      paddingLeft: '10px',
                      color: primaryColors.blue,
                    }}
                  >
                    Note: {trans.note}
                  </Text>
                </View>
              )} */}
            </View>
          ))}
      </View>
    </PdfTemplate>
  );
}

export default TransactionsPdf;
