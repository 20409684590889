export enum ProcessingStatus {
  IDLE = 'idle',
  FAILED = 'failed',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
}

export enum HttpAction {
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete',
}

export enum SnackbarAction {
  SAVE_FAIL,
  DELETE_FAIL,
  SAVE_SUCCESS,
  DELETE_SUCCESS,
}
