import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  space?: number;
}

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: ({ space }): number => space ?? 5,
    },
  })
);

export function Spacer(props: Props): JSX.Element {
  const classes = useStyles(props);
  return <div className={classes.root} />;
}
