import { encrypt } from './security';
import { storeCookie } from './cookies';
import {
  EXPIRATION_DATE,
  TOKEN_LIFETIME_IN_MINS,
} from '@real-estate-portal/constants';

export function storeData(key: string, data: string): void {
  const encData = encrypt(data);

  storeCookie(key, encData);
}

export function setExpirationDate(): void {
  const expiration = 1000 * 60 * TOKEN_LIFETIME_IN_MINS;

  const duration = Date.now() + expiration;
  const expiringAt = new Date(duration);

  storeData(EXPIRATION_DATE, expiringAt.toString());
}
