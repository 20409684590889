import React, { useCallback } from 'react';
import { Text, View } from '@react-pdf/renderer';

import {
  formatTime,
  formatDigit,
  simplifyCycle,
} from '@real-estate-portal/utils';
import {
  SumOption,
  OptionType,
  RentalCycle,
  BillingCycle,
} from '@real-estate-portal/interfaces';

import styles from '../templates/styles';
import PdfTemplate from '../templates/PdfTemplate';

interface Props {
  logo: string;
  totals: SumOption[];
  tenantInfo: OptionType[];
  cycle: string | undefined;
  companyInfo: OptionType[];

  rentals?: RentalCycle[] | undefined;
  billings?: BillingCycle[] | undefined;
}

export function BillingsPdf(props: Props): JSX.Element {
  const {
    logo,
    cycle,
    totals,
    rentals,
    billings,
    tenantInfo,
    companyInfo,
  } = props;

  const isEven = useCallback((n: number): boolean => {
    return n % 2 === 0;
  }, []);

  return (
    <PdfTemplate
      logo={logo}
      cycle={cycle}
      totals={totals}
      reportType="Billing"
      tenantInfo={tenantInfo}
      companyInfo={companyInfo}
    >
      <View style={styles.table}>
        {billings && (
          <View style={styles.row}>
            <View style={[styles.cell, { width: '20%' }]}>
              <Text style={styles.cellText}>Cycle</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Bill</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Charged</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Paid</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Pardon</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>Unit</Text>
            </View>

            <View style={[styles.cell, { width: '23%' }]}>
              <Text style={styles.cellText}>Created</Text>
            </View>
          </View>
        )}
        {billings &&
          billings.map((bill, key) => (
            <View
              key={key}
              style={isEven(key) ? styles.coloredRow : styles.row}
            >
              <View style={[styles.cell, { width: '20%' }]}>
                <Text style={styles.cellText}>
                  {simplifyCycle(bill.cycle.cycle)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{bill.bill.name}</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatDigit(bill.charged_amount)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatDigit(bill.paid_amount)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{formatDigit(bill.pardon)}</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{bill.unit?.name ?? ''}</Text>
              </View>

              <View style={[styles.cell, { width: '25%' }]}>
                <Text style={styles.cellText}>
                  {formatTime(bill.created_at)}
                </Text>
              </View>
            </View>
          ))}
        {rentals &&
          rentals.map((bill, key) => (
            <View
              key={key}
              style={isEven(key) ? styles.coloredRow : styles.row}
            >
              <View style={[styles.cell, { width: '20%' }]}>
                <Text style={styles.cellText}>
                  {simplifyCycle(bill.cycle.cycle)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>Rental</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatDigit(bill.charged_amount)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>
                  {formatDigit(bill.paid_amount)}
                </Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{formatDigit(bill.pardon)}</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.cellText}>{bill.unit?.name ?? ''}</Text>
              </View>

              <View style={[styles.cell, { width: '25%' }]}>
                <Text style={styles.cellText}>
                  {formatTime(bill.created_at)}
                </Text>
              </View>
            </View>
          ))}
      </View>
    </PdfTemplate>
  );
}

export default BillingsPdf;
