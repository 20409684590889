export enum ChargeMode {
  PIN = 'pin',
  NO_AUTH = 'avs_noauth',
  REDIRECT = 'redirect',
}

export enum ResponseStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}
