import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PaginationMetadata } from '@real-estate-portal/interfaces';

const useStyles = makeStyles({
  pagination: {
    marginTop: 10,
    width: '100%',
    minHeight: 40,
    display: 'block',

    '& > span': {
      float: 'left',
      color: 'black',
      margin: '0 4px',
      cursor: 'pointer',
      padding: '8px 16px',
      textDecoration: 'none',
      border: '1px solid #ddd',
      transition: 'background-color .3s',
    },

    '& > span.active': {
      color: 'white',
      backgroundColor: '#4CAF50',
      border: '1px solid #4CAF50',
    },

    '& > span:hover:not(.active)': {
      backgroundColor: '#ddd',
    },
  },
});

interface Props {
  paginationMetaData: PaginationMetadata;
  onChangePage: (newPage: string) => void;
}

interface RefinedLinks {
  active: boolean;
  label: string;
  url: string;
}

export function CustomPagination(props: Props) {
  const classes = useStyles();

  const { paginationMetaData: meta, onChangePage: handleChange } = props;

  const refinedLinks = (): RefinedLinks[] => {
    const refinedLinks = meta.links.filter((link) => {
      return link.label !== '&laquo; Previous' && link.label !== 'Next &raquo;';
    });

    const links = [...refinedLinks];
    let output = '';
    let counter = 1;

    links.forEach((link) => {
      if (counter === 2) {
        output += ' ...';
      } else if (counter < 2 || counter > links.length - 3) {
        output += ' ' + link;
      }

      counter++;
    });

    return links;
  };

  const handleUrlRedirect = (url: string): void => {
    if (url === null) {
      return;
    }

    const urlParams = url.split('?')[1];

    handleChange(urlParams);
  };

  return (
    <div className={classes.pagination}>
      <span onClick={(): void => handleUrlRedirect(meta.prev_page_url)}>
        &laquo;
      </span>
      {'links' in meta &&
        refinedLinks().map((link, key) => (
          <span
            key={key}
            className={link.active ? 'active' : ''}
            onClick={(): void => handleUrlRedirect(link.url)}
          >
            {link.label}
          </span>
        ))}
      <span onClick={(): void => handleUrlRedirect(meta.next_page_url)}>
        &raquo;
      </span>
    </div>
  );
}

export default CustomPagination;
