import {
  Grid,
  GridSize,
  Typography,
  FormControl,
  TextareaAutosize,
} from '@material-ui/core';
import React from 'react';
import cn from 'classnames';

import useStyles from './styles';

interface Props {
  id: string;
  name: string;
  error: boolean;
  label?: string;
  size?: GridSize;
  rowsMin?: number;
  rowsMax?: number;
  htmlFor?: string;
  className?: string;
  minLength?: number;
  isRequired?: boolean;
  isDisabled?: boolean;
  tooltipText?: string;
  placeholder?: string;
  defaultValue?: string;
  errorMessage?: string;
  inputProps?: { [key: string]: string };
}

export function FormTextArea(props: Props): JSX.Element {
  const {
    id,
    size,
    name,
    label,
    error,
    htmlFor,
    rowsMin,
    rowsMax,
    isDisabled,
    className = '',
    placeholder = '',
    errorMessage = '',
  } = props;

  const classes = useStyles();

  return (
    <Grid container item md={size} className={className}>
      <FormControl fullWidth>
        {label && (
          <label htmlFor={htmlFor} className={classes.formLabel}>
            {label}
          </label>
        )}
        <TextareaAutosize
          id={id}
          name={name}
          autoComplete="off"
          disabled={isDisabled}
          minRows={rowsMin ?? 15}
          maxRows={rowsMax ?? 15}
          placeholder={placeholder}
          className={cn(classes.field, { disabled: isDisabled })}
        />
        {error && errorMessage && (
          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
}
