import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SnackbarPayload,
  SnackbarProps as Snackbar,
} from '@real-estate-portal/interfaces';

import { selectSnackbar, enableSnackbar, disableSnackbar } from './slice';

interface UsePopup {
  snackbar: Snackbar;

  turnOffSnackbar: () => void;
  turnOnSnackbar: (action: SnackbarPayload) => void;
}

export function usePopup(): UsePopup {
  const dispatch = useDispatch();
  const snackbar = useSelector(selectSnackbar);

  const turnOnSnackbar = useCallback(
    (action: SnackbarPayload) => {
      dispatch(enableSnackbar(action));
    },
    [dispatch]
  );

  const turnOffSnackbar = useCallback(() => {
    dispatch(disableSnackbar());
  }, [dispatch]);

  return {
    snackbar,
    turnOnSnackbar,
    turnOffSnackbar,
  };
}
