import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { OptionType } from '@real-estate-portal/interfaces';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

type ReactEvent = React.ChangeEvent<HTMLInputElement>;

interface Props {
  name: string;
  value: string;
  options: OptionType[];
  onChange: (val: string) => void;

  legend?: string;
  className?: string;
}

export function FormRadioBtn(props: Props) {
  const classes = useStyles();
  const { value, name, legend, options, onChange: handleOnChange } = props;

  const handleChange = (event: ReactEvent) => {
    handleOnChange((event.target as HTMLInputElement).value);
  };

  if (!options) return <span />;

  return (
    <FormControl component="fieldset">
      {legend && <FormLabel component="legend">{legend}</FormLabel>}

      <RadioGroup
        name={name}
        value={value}
        aria-label={name}
        className={classes.root}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            control={<Radio color="primary" />}
            label={option.label}
            value={option.value ?? ''}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
