import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
  message: string;
  isOpen: boolean;
  severity: AlertProps['severity']; //'error' | 'warning' | 'info' | 'success';

  onClose: () => void;
}

export function CustomizedSnackbar(props: Props) {
  const { isOpen, message, severity, onClose: handleCloseAlert } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      handleCloseAlert();
    }
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}!
      </Alert>
    </Snackbar>
  );
}

export default CustomizedSnackbar;
