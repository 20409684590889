import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callPut,
  callGet,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  ApiBill,
  BillForm,
  ParamsRole,
  RespStatus,
  PropertyBills,
  UnitAssignBill,
} from '@real-estate-portal/interfaces';

export const billsGet = createAsyncThunk(
  'bills/all',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { params, role } = param;
      const { data } = await callGet<ApiBill>(
        getHttpEndpoint(role, `${params}`).billsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billPost = createAsyncThunk(
  'bills/create',
  async (payload: BillForm, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, BillForm>(
        getHttpEndpoint(payload.role).billPost ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billPut = createAsyncThunk(
  'bills/update',
  async (payload: BillForm, thunkAPI) => {
    try {
      const { uuid, ...rest } = payload;
      const { data } = await callPut<RespStatus, BillForm>(
        getHttpEndpoint(payload.role, `/${uuid}`).billPut ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billGet = createAsyncThunk(
  'bills/bills-by-property',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;

      const { data } = await callGet<PropertyBills[]>(
        getHttpEndpoint(role, `/${id}`).billsByPropertyGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billDelete = createAsyncThunk(
  'bills/delete',
  async (params: ParamsRole, thunkAPI) => {
    try {
      const { role, id } = params;
      const { data } = await callDelete<{ success: string }>(
        getHttpEndpoint(role, `/${id}`).billDelete ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billAssign = createAsyncThunk(
  'bills/assign',
  async (params: UnitAssignBill, thunkAPI) => {
    try {
      const { data } = await callPost<{ success: string }, UnitAssignBill>(
        getHttpEndpoint(params.role).billAssign ?? '',
        params
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
