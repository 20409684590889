import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callGet,
  callPut,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  Unit,
  ApiUnits,
  RespStatus,
  ParamsRole,
  TenantAssignUnit,
  TenantAssignUnitResp,
} from '@real-estate-portal/interfaces';
import { Roles } from '@real-estate-portal/enums';

export const unitPostRequest = createAsyncThunk(
  'units/create',
  async (param: Unit, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, Unit>(
        getHttpEndpoint(param.role).unitPost ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitsGetRequest = createAsyncThunk(
  'units/all',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<ApiUnits>(
        getHttpEndpoint(role).unitsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitsNoPagGetRequest = createAsyncThunk(
  'units/all/no-pagination',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<Unit[]>(
        getHttpEndpoint(role).unitsNotPagGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitGetRequest = createAsyncThunk(
  'units/unit',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callGet<Unit>(
        getHttpEndpoint(role, `/${id}`).unitGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitsByUserGetRequest = createAsyncThunk(
  'units/units-by-user',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiUnits>(
        getHttpEndpoint(role, `/${id}${params}`).unitsByUserGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitsByPropertyGetRequest = createAsyncThunk(
  'units/units-by-property',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiUnits>(
        getHttpEndpoint(role, `/${id}${params}`).unitsByPropertyGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitDeclareVacantRequest = createAsyncThunk(
  'units/declare-vacant',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callPut<{ status: string }, null>(
        getHttpEndpoint(role, `/${id}`).unitDeclareVacant ?? '',
        null
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitPutRequest = createAsyncThunk(
  'units/update',
  async (payload: Unit, thunkAPI) => {
    try {
      const { data } = await callPut<RespStatus, Unit>(
        getHttpEndpoint(payload.role, `/${payload.uuid}`).unitPut ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitAssignUser = createAsyncThunk(
  'units/unit/assign',
  async (param: TenantAssignUnit, thunkAPI) => {
    try {
      const { data } = await callPost<TenantAssignUnitResp, TenantAssignUnit>(
        getHttpEndpoint(param.role).unitAssign ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const unitDropUser = createAsyncThunk(
  'units/drop/user',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callDelete<TenantAssignUnitResp>(
        getHttpEndpoint(role, `/${id}`).unitDropUser ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
