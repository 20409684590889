import { createSlice } from '@reduxjs/toolkit';

import {
  LipaNaMpesa,
  Transaction,
  ApiTransaction,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  transactionPut,
  transactionPost,
  transactionsGet,
  lipaNaMpesaPost,
  transactionGetByUser,
  transactionsGetByUnit,
} from './actions';

interface TransactionsState {
  lipaNaMpesa: LipaNaMpesa | undefined;
  transaction: Transaction | undefined;
  transactions: ApiTransaction | undefined;

  transactionGetStatus: ProcessingStatus;
  transactionPutStatus: ProcessingStatus;
  lipaNaMpesaPostStatus: ProcessingStatus;
  transactionsGetStatus: ProcessingStatus;
  transactionPostStatus: ProcessingStatus;
}

export const initialState: TransactionsState = {
  lipaNaMpesa: undefined,
  transaction: undefined,
  transactions: undefined,
  transactionGetStatus: ProcessingStatus.IDLE,
  transactionPutStatus: ProcessingStatus.IDLE,
  lipaNaMpesaPostStatus: ProcessingStatus.IDLE,
  transactionPostStatus: ProcessingStatus.IDLE,
  transactionsGetStatus: ProcessingStatus.IDLE,
};

interface TransactionsSlice {
  transaction: TransactionsState;
}

export const transactionsSlice = createSlice({
  initialState,
  reducers: {
    resetDataStates: (state) => {
      state.lipaNaMpesa = undefined;
      state.transaction = undefined;
      state.transactions = undefined;
    },
    resetStatus: (state) => {
      state.transactionGetStatus = ProcessingStatus.IDLE;
      state.transactionPutStatus = ProcessingStatus.IDLE;
      state.lipaNaMpesaPostStatus = ProcessingStatus.IDLE;
      state.transactionPostStatus = ProcessingStatus.IDLE;
      state.transactionsGetStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'transactions',
  extraReducers: (builder) => {
    builder.addCase(transactionsGet.pending, (state) => {
      state.transactionsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(transactionsGet.fulfilled, (state, action) => {
      state.transactionsGetStatus = ProcessingStatus.SUCCEEDED;
      state.transactions = action.payload;
    });
    builder.addCase(transactionsGet.rejected, (state) => {
      state.transactionsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(transactionsGetByUnit.pending, (state) => {
      state.transactionsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(transactionsGetByUnit.fulfilled, (state, action) => {
      state.transactionsGetStatus = ProcessingStatus.SUCCEEDED;
      state.transactions = action.payload;
    });
    builder.addCase(transactionsGetByUnit.rejected, (state) => {
      state.transactionsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(transactionGetByUser.pending, (state) => {
      state.transactionsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(transactionGetByUser.fulfilled, (state, action) => {
      state.transactionsGetStatus = ProcessingStatus.SUCCEEDED;
      state.transactions = action.payload;
    });
    builder.addCase(transactionGetByUser.rejected, (state) => {
      state.transactionsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(transactionPost.pending, (state) => {
      state.transactionPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(transactionPost.fulfilled, (state, action) => {
      state.transactionPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(transactionPost.rejected, (state) => {
      state.transactionPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(transactionPut.pending, (state) => {
      state.transactionPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(transactionPut.fulfilled, (state, action) => {
      state.transactionPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(transactionPut.rejected, (state) => {
      state.transactionPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(lipaNaMpesaPost.pending, (state) => {
      state.lipaNaMpesaPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(lipaNaMpesaPost.fulfilled, (state, action) => {
      state.lipaNaMpesaPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(lipaNaMpesaPost.rejected, (state) => {
      state.lipaNaMpesaPostStatus = ProcessingStatus.FAILED;
    });
  },
});

export const transactionsReducer = transactionsSlice.reducer;
export const { resetDataStates, resetStatus } = transactionsSlice.actions;

export const selectTransactionsGetStatus = (
  state: TransactionsSlice
): ProcessingStatus => state.transaction.transactionsGetStatus;

export const selectLipaNaMpesaPostStatus = (
  state: TransactionsSlice
): ProcessingStatus => state.transaction.lipaNaMpesaPostStatus;

export const selectTransactionGetStatus = (
  state: TransactionsSlice
): ProcessingStatus => state.transaction.transactionGetStatus;

export const selectTransactionPostStatus = (
  state: TransactionsSlice
): ProcessingStatus => state.transaction.transactionPostStatus;

export const selectTransactionPutStatus = (
  state: TransactionsSlice
): ProcessingStatus => state.transaction.transactionPutStatus;

export const selectTransactions = (
  state: TransactionsSlice
): ApiTransaction | undefined => state.transaction.transactions;

export const selectTransaction = (
  state: TransactionsSlice
): Transaction | undefined => state.transaction.transaction;

export const selectLipaNaMpesa = (
  state: TransactionsSlice
): LipaNaMpesa | undefined => state.transaction.lipaNaMpesa;
