export const primaryColors = {
  red: '#D54C4C',
  blue: '#3F51B5',
  white: '#FFFFFF',
  black: '#000000',
  lightGrey: '#AAABB8',
};

export const secondaryColors = {
  darkGrey: '#636060',
  lightGrey: '#e3e3e3',
  creamGrey: '#F5F5F5',

  grey: {
    grey001: '#dddddd',
    grey002: '#F5F5F5',
    grey003: '#C0C0C0',
  },
};
