import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    marginTop: '10%',
    fontSize: '50px',
  },
}));

export function PageNotFound(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.text}>
        404
      </Typography>
    </div>
  );
}
