import { Roles } from '@real-estate-portal/enums';

interface Endpoints {
  user: string;
  login: string;
  logout: string;
  updateUser: string;
  passwordUpdate: string;

  admins?: string;
  docPost?: string;
  billPut?: string;
  unitGet?: string;
  unitPut?: string;
  billsGet?: string;
  billPost?: string;
  register?: string;
  docFetch?: string;
  unitPost?: string;
  unitsGet?: string;
  searchGet?: string;
  cyclesGet?: string;
  tenantGet?: string;
  tenantPut?: string;
  reportGet?: string;
  docDelete?: string;
  companies?: string;
  tempAdmins?: string;
  reportsGet?: string;
  billingPut?: string;
  billAssign?: string;
  billDelete?: string;
  paymentPut?: string;
  tenantPost?: string;
  tenantsGet?: string;
  unitAssign?: string;
  adminDelete?: string;
  cronLogsGet?: string;
  propertyGet?: string;
  propertyPut?: string;
  settingsPut?: string;
  settingsGet?: string;
  unitDropUser?: string;
  settingsPost?: string;
  propertyPost?: string;
  tenantDelete?: string;
  propertiesGet?: string;
  systemLogsGet?: string;
  billingByUser?: string;
  billingByUnit?: string;
  resetPassword?: string;
  settingsEnvPut?: string;
  forgotPassword?: string;
  settingsEnvGet?: string;
  propertyDelete?: string;
  unitsByUserGet?: string;
  unitsNotPagGet?: string;
  transactionPut?: string;
  registerCompany?: string;
  transactionsGet?: string;
  transactionPost?: string;
  waterReadingGet?: string;
  tenantsByUnitGet?: string;
  changeAdminState?: string;
  waterReadingPost?: string;
  lipaNameMpesaPost?: string;
  unitDeclareVacant?: string;
  unitsByPropertyGet?: string;
  billsByPropertyGet?: string;
  tenantsByPropertyGet?: string;
  transactionsByUnitGet?: string;
  transactionsByUserGet?: string;
  waterReadingsByPropsGet?: string;
}

export function getHttpEndpoint(role: Roles, param = ''): Endpoints {
  let endPoints = {} as Endpoints;

  if (role === Roles.ADMIN) {
    const service = 'admin';

    endPoints = {
      user: `${service}/user`,
      logout: `${service}/logout`,
      billsGet: `${service}/bills`,
      unitPost: `${service}/units`,
      unitsGet: `${service}/units`,
      billPost: `${service}/bills`,
      cyclesGet: `${service}/cycles`,
      docPost: `${service}/document`,
      billAssign: `${service}/charges`,
      reportsGet: `${service}/reports`,
      paymentPut: `${service}/payments`,
      tenantPost: `${service}/tenants/`,
      unitAssign: `${service}/occupants`,
      billPut: `${service}/bills${param}`,
      settingsPost: `${service}/settings`,
      unitGet: `${service}/units${param}`,
      unitPut: `${service}/units${param}`,
      tempAdmins: `${service}/temp-admins`,
      updateUser: `${service}/update-user`,
      propertyPost: `${service}/properties`,
      billDelete: `${service}/bills${param}`,
      searchGet: `${service}/search${param}`,
      adminDelete: `${service}/admin${param}`,
      tenantGet: `${service}/tenants${param}`,
      tenantPut: `${service}/tenants${param}`,
      docFetch: `${service}/document${param}`,
      systemLogsGet: `${service}/logs${param}`,
      docDelete: `${service}/document${param}`,
      tenantsGet: `${service}/tenants${param}`,
      billingPut: `${service}/billings${param}`,
      resetPassword: `${service}/reset-password`,
      register: `${service}/register-temp-admin`,
      transactionsGet: `${service}/transactions`,
      transactionPost: `${service}/transactions`,
      tenantDelete: `${service}/tenants${param}`,
      settingsPut: `${service}/settings${param}`,
      settingsGet: `${service}/settings${param}`,
      forgotPassword: `${service}/forgot-password`,
      propertyGet: `${service}/properties${param}`,
      propertyPut: `${service}/properties${param}`,
      passwordUpdate: `${service}/update-password`,
      billingByUnit: `${service}/billings${param}`,
      unitDropUser: `${service}/occupants${param}`,
      settingsEnvGet: `${service}/show-env-settings`,
      billsByPropertyGet: `${service}/bills${param}`,
      propertiesGet: `${service}/properties${param}`,
      propertyDelete: `${service}/properties${param}`,
      settingsEnvPut: `${service}/update-env-settings`,
      unitsNotPagGet: `${service}/units-no-pagination`,
      tenantsByUnitGet: `${service}/occupants${param}`,
      transactionPut: `${service}/transactions${param}`,
      unitsByUserGet: `${service}/units-by-user${param}`,
      waterReadingGet: `${service}/water-readings${param}`,
      billingByUser: `${service}/billings-by-user${param}`,
      waterReadingPost: `${service}/create-water-readings`,
      cronLogsGet: `${service}/billing-cycle-logs${param}`,
      tenantsByPropertyGet: `${service}/unit-tenants${param}`,
      transactionsByUserGet: `${service}/transactions${param}`,
      transactionsByUnitGet: `${service}/transactions${param}`,
      login: `${service}/login?api_key=${process.env.NX_A_KEY}`,
      unitsByPropertyGet: `${service}/units-by-property${param}`,
      unitDeclareVacant: `${service}/unit-declare-vacant${param}`,
      waterReadingsByPropsGet: `${service}/water-readings-by-property${param}`,
    };
  }

  if (role === Roles.TENANT) {
    const service = 'tenant';

    endPoints = {
      user: `${service}/user`,
      login: `${service}/login`,
      logout: `${service}/logout`,
      cyclesGet: `${service}/cycles`,
      register: 'admin/register-tenant',
      settingsPost: `${service}/settings`,
      updateUser: `${service}/update-user`,
      docFetch: `${service}/document${param}`,
      resetPassword: `${service}/reset-password`,
      settingsPut: `${service}/settings${param}`,
      settingsGet: `${service}/settings${param}`,
      billingByUser: `${service}/billing${param}`,
      forgotPassword: `${service}/forgot-password`,
      passwordUpdate: `${service}/update-password`,
      lipaNameMpesaPost: `${service}/lipa-na-mpesa`,
      transactionsByUserGet: `${service}/transactions${param}`,
    };
  }

  if (role === Roles.SUPER_ADMIN) {
    const service = 'super-admin';

    endPoints = {
      user: `${service}/user`,
      logout: `${service}/logout`,
      admins: `${service}/admins${param}`,
      tempAdmins: `${service}/temp-admins`,
      updateUser: `${service}/update-user`,
      systemLogsGet: `${service}/logs${param}`,
      companies: `${service}/companies${param}`,
      register: `${service}/register-temp-admin`,
      passwordUpdate: `${service}/update-password`,
      settingsEnvGet: `${service}/show-env-settings`,
      registerCompany: `${service}/register-company`,
      settingsEnvPut: `${service}/update-env-settings`,
      cronLogsGet: `${service}/billing-cycle-logs${param}`,
      changeAdminState: `${service}/change-admin-state${param}`,
      login: `${service}/login?api_key=${process.env.NX_SA_KEY}`,
    };
  }

  return endPoints;
}
