import React from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  Grid,
  GridSize,
  TextField,
  Typography,
  FormControl,
} from '@material-ui/core';
import useStyles from './styles';

type Type = 'text' | 'password' | 'email' | 'number';

interface Props {
  id: string;
  type: Type;
  name: string;
  error: boolean;

  rows?: number;
  label?: string;
  size?: GridSize;
  htmlFor?: string;
  control?: Control;
  minLength?: number;
  className?: string;
  multiline?: boolean;
  inputStyle?: string;
  'data-test'?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  defaultValue?: string;
  inputProps?: { [key: string]: string };

  onFocus?: () => void;
}

export function FormTextField(props: Props): JSX.Element {
  const {
    id,
    rows,
    size,
    name,
    type,
    label,
    error,
    htmlFor,
    onFocus,
    control,
    multiline,
    minLength,
    inputProps,
    isDisabled,
    defaultValue,
    className = '',
    inputStyle = '',
    placeholder = '',
    errorMessage = '',
    isRequired = false,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Grid container item md={size} className={className}>
      <FormControl fullWidth>
        {label && (
          <label htmlFor={htmlFor} className={classes.formLabel}>
            {label}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{ required: isRequired, minLength }}
          render={({ name, value, onChange, onBlur }): JSX.Element => (
            <TextField
              fullWidth
              id={id}
              type={type}
              name={name}
              size="small"
              error={error}
              value={value}
              minRows={rows}
              maxRows={rows}
              margin="normal"
              variant="outlined"
              autoComplete="off"
              multiline={multiline}
              disabled={isDisabled}
              placeholder={placeholder}
              inputProps={{ ...inputProps, onFocus }}
              InputProps={{ classes: { input: inputStyle } }}
              onChange={(e): void => {
                onChange(e);
              }}
              onBlur={onBlur}
            />
          )}
        />
        {error && errorMessage && (
          <Typography
            color="error"
            variant="subtitle2"
            className={classes.errorText}
          >
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
}
