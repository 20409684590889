import { OptionType, SnackbarProps } from '@real-estate-portal/interfaces';

export const TOKEN_LIFETIME_IN_MINS = 60;
export const TOKEN_STORAGE_NAME = 'repo';
export const EXPIRATION_DATE = 'expirationDate';

export const LOGIN_ERR_MSG = 'Something went wrong!!';
export const DEL_MSG = 'Are you sure you want to delete this entry ?';

export const paymentOptions = [
  {
    label: 'M-pesa',
    value: 'm-pesa',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Cheque',
    value: 'cheque',
  },
  {
    label: 'EFT',
    value: 'eft',
  },
];

export const snackbarDefaultValues: SnackbarProps = {
  message: '',
  isOpen: false,
  severity: 'success',
};

export const yesNoOptions = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
];

export const countOptions: OptionType[] = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
];

export const waterLists = ['waters', 'Water', 'water'];
