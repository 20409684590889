import CancelIcon from './cancel.svg';
import ElectricianIcon from './electrician.svg';
import FileIcon from './files.svg';
import ForumIcon from './forum.svg';
import LogoutIcon from './logout.svg';
import NotificationIcon from './notifications.svg';
import PaymentIcon from './payment.svg';
import PlumberIcon from './plumber.svg';
import PropertyIcon from './property.svg';
import ReportIcon from './report.svg';
import SettingsIcon from './settings.svg';
import SupportIcon from './support.svg';
import UploadIcon from './upload.svg';
import UsersIcon from './users.svg';

export const Icon = {
  CancelIcon,
  ElectricianIcon,
  FileIcon,
  ForumIcon,
  LogoutIcon,
  NotificationIcon,
  PaymentIcon,
  PlumberIcon,
  PropertyIcon,
  ReportIcon,
  SettingsIcon,
  SupportIcon,
  UploadIcon,
  UsersIcon,
};
