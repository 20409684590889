import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import cn from 'classnames';

interface Props {
  height?: number;
  className?: string;
}

export function Spinner({ height = 100, className }: Props): JSX.Element {
  const backgroundColor = 'transparent';

  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      className={cn(className)}
      style={{ height: `${height}vh`, backgroundColor }}
    >
      <Grid item>
        <CircularProgress size={80} />
      </Grid>
    </Grid>
  );
}
