import { Icon } from '@real-estate-portal/assets';
import { RealEstatePaths } from '@real-estate-portal/enums';

interface AccountMenus {
  icon: string;
  name: string;
  route: RealEstatePaths;
}

export const accountMenus: AccountMenus[] = [
  {
    name: 'Dashboard',
    route: RealEstatePaths.DASHBOARD,
    icon: Icon.ReportIcon,
  },
  {
    name: 'Payments',
    route: RealEstatePaths.PAYMENTS,
    icon: Icon.PaymentIcon,
  },
  {
    name: 'Tenants',
    route: RealEstatePaths.TENANTS,
    icon: Icon.UsersIcon,
  },
  {
    name: 'Properties',
    route: RealEstatePaths.PROPERTIES,
    icon: Icon.PropertyIcon,
  },
  // {
  //   name: 'Documents',
  //   route: RealEstatePaths.SETTINGS,
  //   icon: Icon.FileIcon,
  // },
  {
    name: 'Notifications (2)',
    route: RealEstatePaths.NOTIFICATIONS,
    icon: Icon.NotificationIcon,
  },
  {
    name: 'Reports',
    route: RealEstatePaths.SETTINGS,
    icon: Icon.ReportIcon,
  },
  // {
  //   name: 'Support',
  //   route: RealEstatePaths.SETTINGS,
  //   icon: Icon.SupportIcon,
  // },
  {
    name: 'Settings',
    route: RealEstatePaths.SETTINGS,
    icon: Icon.SettingsIcon,
  },
];
