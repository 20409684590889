import {
  SnackbarProps,
  SnackbarSeverity,
} from '@real-estate-portal/interfaces';

export function getSnackbarProps(
  isOpen: boolean,
  message: string,
  severity: SnackbarSeverity
): SnackbarProps {
  return {
    isOpen,
    message,
    severity,
  };
}
