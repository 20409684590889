import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';
import { CustomButton, FormSelectField } from '../../form';

interface Props {
  title: string;
  btnText?: string;
  isFilterable?: boolean;
  onBtnClick?: () => void;
}

export function BodyHeader(props: Props): JSX.Element {
  const {
    title,
    btnText,
    isFilterable = false,
    onBtnClick: handleClick,
  } = props;
  const classes = useStyles({ isFilterable });

  return (
    <Grid className={classes.root} container>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>

      <div className={classes.btnContainer}>
        {isFilterable && (
          <div className={classes.filter}>
            {/*<FormSelectField  name={} options={} value={}/>*/}
          </div>
        )}
        {handleClick && (
          <CustomButton
            theme="primary"
            id="bodyHeader-cta"
            text={btnText ?? 'Add'}
            onClick={handleClick}
          />
        )}
      </div>
    </Grid>
  );
}
