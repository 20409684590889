import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  LoginForm,
  RegisterForm,
  LoginResponse,
  PasswordReset,
  ForgotPassword,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  loginPost,
  registerPost,
  resetPasswordPost,
  forgotPasswordPost,
} from './actions';
import {
  resetState,
  selectTempPass,
  selectAuthData,
  selectLoginStatus,
  selectRegisterStatus,
  selectResetPasswordStatus,
} from './slice';

interface UseAuth {
  loginStatus: ProcessingStatus;
  tempPassword: string | undefined;
  registerStatus: ProcessingStatus;
  authData: LoginResponse | undefined;
  resetPasswordStatus: ProcessingStatus;

  resetStateToDefault: () => void;
  login: (data: LoginForm) => void;
  register: (data: RegisterForm) => void;
  resetPassword: (data: PasswordReset) => void;
  forgotPassword: (data: ForgotPassword) => void;
}

export function useAuth(): UseAuth {
  const dispatch = useDispatch();
  const authData = useSelector(selectAuthData);
  const tempPassword = useSelector(selectTempPass);
  const loginStatus = useSelector(selectLoginStatus);
  const registerStatus = useSelector(selectRegisterStatus);
  const resetPasswordStatus = useSelector(selectResetPasswordStatus);

  const login = useCallback(
    (data: LoginForm) => {
      dispatch(loginPost(data));
    },
    [dispatch]
  );

  const forgotPassword = useCallback(
    (data: ForgotPassword) => {
      dispatch(forgotPasswordPost(data));
    },
    [dispatch]
  );

  const resetPassword = useCallback(
    (data: PasswordReset) => {
      dispatch(resetPasswordPost(data));
    },
    [dispatch]
  );

  const resetStateToDefault = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const register = useCallback(
    (data: RegisterForm) => {
      dispatch(registerPost(data));
    },
    [dispatch]
  );

  return {
    login,
    authData,
    register,
    loginStatus,
    tempPassword,
    resetPassword,
    forgotPassword,
    registerStatus,
    resetStateToDefault,
    resetPasswordStatus,
  };
}
