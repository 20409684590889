import { makeStyles } from '@material-ui/core';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() => ({
  fileWrapper: {
    padding: 5,
    width: '100%',
    display: 'flex',
    borderRadius: 5,
    marginBottom: '2%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadWrapper: {
    border: `1px solid ${secondaryColors.grey.grey001}`,
  },
  fileName: {
    fontSize: '0.7rem',
  },
  fileCta: {
    marginRight: '5%',
    cursor: 'pointer',
    fontSize: '0.5rem',
    color: primaryColors.blue,
    textDecoration: 'underline',

    '&:last-child': {
      color: primaryColors.red,
    },
  },
  fileCtaWrapper: {
    width: '100%',
    marginTop: '3%',
    flexDirection: 'row',
    display: 'inline-flex',
  },
  time: {
    display: 'block',
    fontSize: '0.5rem',
    color: primaryColors.lightGrey,
  },
  uploadFileBtn: {
    margin: 5,
  },
}));

export default useStyles;
