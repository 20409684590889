import React from 'react';
import * as Sentry from '@sentry/react';
import { CssBaseline } from '@material-ui/core';
import { BrowserTracing } from '@sentry/tracing';

import { api, assignTokenToHeader } from '@real-estate-portal/utils';

import Router from '../routing/Router';
import StoreProvider from '../store/StoreProvider';
import { environment } from '../environments/environment';

Sentry.init({
  dsn: environment.sentryDns,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

api.interceptors.request.use(
  (config) =>
    assignTokenToHeader.fulfilled({
      ...config,
    }),
  assignTokenToHeader.rejected
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (window.location.pathname === '/') {
      return;
    }

    if (error.response.status === 401) {
      window.location.href = '/';
    }

    return error;
  }
);

export const App = (): JSX.Element => {
  return (
    <StoreProvider>
      <CssBaseline />
      <Router />
    </StoreProvider>
  );
};

export default App;
