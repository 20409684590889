import React from 'react';
import { Typography } from '@material-ui/core';
import cn from 'classnames';

import useStyles from './styles';

interface Props {
  icon: string;
  url?: string;
  text: string;
  className?: string;
  onclick?: () => void;
}

export function Menu(props: Props): JSX.Element {
  const { icon, text, className, onclick: handleClick } = props;
  const classes = useStyles();

  return (
    <div className={cn(classes.thumbnailCard, className)} onClick={handleClick}>
      <img className={classes.icon} src={icon} alt="report icon" />
      <Typography className={classes.IconText} variant="subtitle2">
        {text}
      </Typography>
    </div>
  );
}
