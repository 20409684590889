import { LoginResponse } from '@real-estate-portal/interfaces';
import { TOKEN_STORAGE_NAME } from '@real-estate-portal/constants';

import { logout } from './logout';
import { callPost } from './http-request';
import { setExpirationDate, storeData } from './local-storage';

export async function refreshToken(): Promise<void> {
  try {
    const { data } = await callPost<LoginResponse, unknown>('auth/refresh');

    if (!data.access_token) {
      logout();
      return;
    }

    storeData(TOKEN_STORAGE_NAME, data.access_token);
    setExpirationDate();
  } catch (e) {
    return;
  }
}
