import { makeStyles } from '@material-ui/core/styles';
import { secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() => ({
  formLabel: {
    color: 'black',
    fontSize: '0.8125rem',
    marginTop: 15,
  },
  field: {
    marginTop: '5px !important',
    border: 'thin solid ' + secondaryColors.lightGrey,
    padding: 15,
    '&.disabled': {
      backgroundColor: 'grey',
    },
  },
}));

export default useStyles;
