import SimpleCrypto from 'simple-crypto-js';

export function encrypt(msg: string): string {
  const crypto = new SimpleCrypto(process.env.NX_STORAGE_KEY);

  return crypto.encrypt(msg);
}

export function decrypt(transitmessage: string): string | unknown {
  const crypto = new SimpleCrypto(process.env.NX_STORAGE_KEY);

  try {
    return crypto.decrypt(transitmessage);
  } catch (e) {
    return transitmessage;
  }
}
