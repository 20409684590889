import { StyleSheet } from '@react-pdf/renderer';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

const styles = StyleSheet.create({
  pdfViewer: {
    width: '100%',
  },
  status: {
    color: primaryColors.blue,
  },
  page: {
    width: '100%',
    fontWeight: 'normal',
    fontFamily: 'Helvetica',
    backgroundColor: primaryColors.white,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: primaryColors.white,
  },
  headerWrapper: {
    padding: 5,
    marginTop: 15,
  },
  headerInnerWrapper: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  headerLabel: {
    width: 100,
    marginRight: 5,
    fontSize: '11px',
    textAlign: 'right',
    color: primaryColors.blue,
    border: `1px solid ${secondaryColors.grey.grey001}`,
  },
  headerValue: {
    fontSize: '12px',
  },
  logo: {
    width: 200,
    marginTop: '5%',
  },
  dateWrapper: {
    width: '50%',
    minHeight: 50,
    margin: '0 auto',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${primaryColors.lightGrey}`,
  },
  cycle: {
    fontSize: '12px',
  },
  statsWrapper: {
    width: '95%',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sumTitle: {
    fontSize: '12px',
  },
  sumValue: {
    fontSize: '18px',
    marginLeft: '10px',
    fontWeight: 'bold',
  },
  tableFooterWrapper: {
    padding: 5,
    marginTop: 15,
    display: 'flex',
    flexBasis: '30%',
    border: `1px dashed ${secondaryColors.grey.grey001}`,
  },
  table: {
    width: '95%',
    marginTop: 10,
    margin: '10px auto',
  },
  row: {
    width: '100%',
    display: 'flex',
    minHeight: '40px',
    flexDirection: 'row',
    borderBottom: `1px dashed ${secondaryColors.grey.grey001}`,
  },
  cell: {
    width: '16%',
    display: 'flex',
    justifyContent: 'center',
  },
  cellText: {
    fontSize: '11.5px',
    paddingLeft: '10px',
  },
  coloredRow: {
    width: '100%',
    display: 'flex',
    minHeight: '40px',
    flexDirection: 'row',
    backgroundColor: secondaryColors.grey.grey002,
    borderBottom: `1px dashed ${secondaryColors.grey.grey001}`,
  },
  transNote: {
    width: '100%',
    paddingBottom: '5px',
  },
  transFlex: {
    height: '40px',
    display: 'flex',
    marginTop: '5px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  transFlexB: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTrans: {
    width: '100%',
    minHeight: '40px',
    borderBottom: `1px dashed ${secondaryColors.grey.grey001}`,
  },
  coloredRowTrans: {
    width: '100%',
    minHeight: '40px',
    backgroundColor: secondaryColors.grey.grey002,
    borderBottom: `1px dashed ${secondaryColors.grey.grey001}`,
  },
  transText: {
    fontSize: '10px',
    paddingLeft: '10px',
  },
});

export default styles;
