import { createSlice } from '@reduxjs/toolkit';

import {
  tenantGet,
  tenantPut,
  tenantPost,
  tenantsGet,
  tenantDelete,
  tenantsPerUnitGet,
  tenantsPerPropertyGet,
} from './actions';
import {
  Tenant,
  ApiTenant,
  ApiTenantToUnit,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

interface TenantsState {
  tenant: Tenant | undefined;
  tenants: ApiTenant | undefined;
  tenantsToUnit: ApiTenantToUnit | undefined;

  tenantGetStatus: ProcessingStatus;
  tenantPutStatus: ProcessingStatus;
  tenantsGetStatus: ProcessingStatus;
  tenantPostStatus: ProcessingStatus;
  tenantDeleteStatus: ProcessingStatus;
  tenantsToUnitGetStatus: ProcessingStatus;
  tenantsPropertyGetStatus: ProcessingStatus;
}

export const initialState: TenantsState = {
  tenant: undefined,
  tenants: undefined,
  tenantsToUnit: undefined,

  tenantGetStatus: ProcessingStatus.IDLE,
  tenantPutStatus: ProcessingStatus.IDLE,
  tenantsGetStatus: ProcessingStatus.IDLE,
  tenantPostStatus: ProcessingStatus.IDLE,
  tenantDeleteStatus: ProcessingStatus.IDLE,
  tenantsToUnitGetStatus: ProcessingStatus.IDLE,
  tenantsPropertyGetStatus: ProcessingStatus.IDLE,
};

interface TenantsSlice {
  tenants: TenantsState;
}

export const tenantsSlice = createSlice({
  initialState,
  name: 'tenants',
  reducers: {
    resetState: (state) => {
      state.tenant = undefined;
      state.tenants = undefined;
      state.tenantsToUnit = undefined;
    },
    resetStatusStates: (state) => {
      state.tenantGetStatus = ProcessingStatus.IDLE;
      state.tenantPutStatus = ProcessingStatus.IDLE;
      state.tenantsGetStatus = ProcessingStatus.IDLE;
      state.tenantPostStatus = ProcessingStatus.IDLE;
      state.tenantDeleteStatus = ProcessingStatus.IDLE;
      state.tenantsToUnitGetStatus = ProcessingStatus.IDLE;
      state.tenantsPropertyGetStatus = ProcessingStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tenantPost.pending, (state) => {
      state.tenantPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tenantPost.fulfilled, (state, { payload }) => {
      state.tenantPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(tenantPost.rejected, (state) => {
      state.tenantPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tenantPut.pending, (state) => {
      state.tenantPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tenantPut.fulfilled, (state, { payload }) => {
      state.tenantPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(tenantPut.rejected, (state) => {
      state.tenantPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tenantsGet.pending, (state) => {
      state.tenantsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tenantsGet.fulfilled, (state, { payload }) => {
      state.tenantsGetStatus = ProcessingStatus.SUCCEEDED;
      state.tenants = payload;
    });
    builder.addCase(tenantsGet.rejected, (state) => {
      state.tenantsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tenantsPerPropertyGet.pending, (state) => {
      state.tenantsPropertyGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tenantsPerPropertyGet.fulfilled, (state, { payload }) => {
      state.tenantsPropertyGetStatus = ProcessingStatus.SUCCEEDED;
      state.tenants = payload;
    });
    builder.addCase(tenantsPerPropertyGet.rejected, (state) => {
      state.tenantsPropertyGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tenantDelete.pending, (state) => {
      state.tenantDeleteStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tenantDelete.fulfilled, (state, { payload }) => {
      state.tenantDeleteStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(tenantDelete.rejected, (state) => {
      state.tenantDeleteStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tenantGet.pending, (state) => {
      state.tenantGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(tenantGet.fulfilled, (state, { payload }) => {
      state.tenantGetStatus = ProcessingStatus.SUCCEEDED;
      state.tenant = payload;
    });
    builder.addCase(tenantGet.rejected, (state) => {
      state.tenantGetStatus = ProcessingStatus.FAILED;
    });
    builder.addCase(tenantsPerUnitGet.pending, (state) => {
      state.tenantsToUnitGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(tenantsPerUnitGet.fulfilled, (state, { payload }) => {
      state.tenantsToUnitGetStatus = ProcessingStatus.SUCCEEDED;
      state.tenantsToUnit = payload;
    });

    builder.addCase(tenantsPerUnitGet.rejected, (state) => {
      state.tenantsToUnitGetStatus = ProcessingStatus.FAILED;
    });
  },
});

export const tenantsReducer = tenantsSlice.reducer;
export const { resetState, resetStatusStates } = tenantsSlice.actions;

export const selectTenantsGetStatus = (state: TenantsSlice): ProcessingStatus =>
  state.tenants.tenantsGetStatus;

export const selectTenantsPropertyGetStatus = (
  state: TenantsSlice
): ProcessingStatus => state.tenants.tenantsPropertyGetStatus;

export const selectTenantGetStatus = (state: TenantsSlice): ProcessingStatus =>
  state.tenants.tenantGetStatus;

export const selectTenantPostStatus = (state: TenantsSlice): ProcessingStatus =>
  state.tenants.tenantPostStatus;

export const selectTenantPutStatus = (state: TenantsSlice): ProcessingStatus =>
  state.tenants.tenantPutStatus;

export const selectTenantDeleteStatus = (
  state: TenantsSlice
): ProcessingStatus => state.tenants.tenantDeleteStatus;

export const selectTenantToUnitStatus = (
  state: TenantsSlice
): ProcessingStatus => state.tenants.tenantsToUnitGetStatus;

export const selectTenant = (state: TenantsSlice): ApiTenant | undefined =>
  state.tenants.tenants;

export const selectTenantsToUnit = (
  state: TenantsSlice
): ApiTenantToUnit | undefined => state.tenants.tenantsToUnit;

export const selectSingleTenant = (state: TenantsSlice): Tenant | undefined =>
  state.tenants.tenant;
