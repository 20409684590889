import React from 'react';
import { Backdrop, CircularProgress, Fade, Modal } from '@material-ui/core';
import { Icon } from '@real-estate-portal/assets';

import useStyles from './styles';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;

  modalWidth?: number;
  isLoading?: boolean;
}

export function CustomModal(props: Props): JSX.Element {
  const {
    isOpen,
    children,
    modalWidth,
    onClick: handleCloseModal,
    isLoading = false,
  } = props;
  const classes = useStyles({ modalWidth });

  return (
    <Modal
      open={isOpen}
      disableAutoFocus
      disableEnforceFocus
      closeAfterTransition
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          {isLoading && (
            <CircularProgress size={40} className={classes.btnProgress} />
          )}
          <img
            alt="modal close icon"
            src={Icon.CancelIcon}
            className={classes.icon}
            onClick={handleCloseModal}
          />
          <div style={{ marginBottom: 30 }}>
            {isLoading ? <span /> : children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
