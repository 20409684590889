import { createSlice } from '@reduxjs/toolkit';

import { ProcessingStatus } from '@real-estate-portal/enums';
import {
  Cycle,
  Billing,
  IWaterReadings,
  ApiWaterReadings,
} from '@real-estate-portal/interfaces';

import {
  billingPut,
  billingCyclesGet,
  waterReadingsGet,
  billingsByUnitGet,
  billingsByUserGet,
  waterReadingsPost,
  waterReadingsAllGet,
} from './actions';

interface BillingsState {
  billingCycles: Cycle[];
  billings: Billing | undefined;
  billingsGetStatus: ProcessingStatus;
  billingsPutStatus: ProcessingStatus;
  billingsCycleGetStatus: ProcessingStatus;
  waterReadingsGetStatus: ProcessingStatus;
  waterReadings: IWaterReadings | undefined;
  waterReadingsPostStatus: ProcessingStatus;
  waterReadingsAllGetStatus: ProcessingStatus;
  waterReadingsAll: ApiWaterReadings | undefined;
}

export const initialState: BillingsState = {
  billingCycles: [],
  billings: undefined,
  waterReadings: undefined,
  waterReadingsAll: undefined,
  billingsGetStatus: ProcessingStatus.IDLE,
  billingsPutStatus: ProcessingStatus.IDLE,
  billingsCycleGetStatus: ProcessingStatus.IDLE,
  waterReadingsGetStatus: ProcessingStatus.IDLE,
  waterReadingsPostStatus: ProcessingStatus.IDLE,
  waterReadingsAllGetStatus: ProcessingStatus.IDLE,
};

interface BillingsSlice {
  billings: BillingsState;
}

export const billingsSlice = createSlice({
  initialState,
  reducers: {
    clearState: (state) => {
      state.billings = undefined;
      state.waterReadings = undefined;
      state.waterReadingsAll = undefined;
    },
    clearStatus: (state) => {
      state.billingsGetStatus = ProcessingStatus.IDLE;
      state.billingsPutStatus = ProcessingStatus.IDLE;
      state.billingsCycleGetStatus = ProcessingStatus.IDLE;
      state.waterReadingsGetStatus = ProcessingStatus.IDLE;
      state.waterReadingsPostStatus = ProcessingStatus.IDLE;
      state.waterReadingsAllGetStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'billings',
  extraReducers: (builder) => {
    builder.addCase(billingsByUnitGet.pending, (state) => {
      state.billingsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billingsByUnitGet.fulfilled, (state, action) => {
      state.billingsGetStatus = ProcessingStatus.SUCCEEDED;
      state.billings = action.payload;
    });
    builder.addCase(billingsByUnitGet.rejected, (state) => {
      state.billingsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billingPut.pending, (state) => {
      state.billingsPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billingPut.fulfilled, (state, action) => {
      state.billingsPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(billingPut.rejected, (state) => {
      state.billingsPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billingsByUserGet.pending, (state) => {
      state.billingsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billingsByUserGet.fulfilled, (state, action) => {
      state.billingsGetStatus = ProcessingStatus.SUCCEEDED;
      state.billings = action.payload;
    });
    builder.addCase(billingsByUserGet.rejected, (state) => {
      state.billingsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billingCyclesGet.pending, (state) => {
      state.billingsCycleGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billingCyclesGet.fulfilled, (state, action) => {
      state.billingsCycleGetStatus = ProcessingStatus.SUCCEEDED;
      state.billingCycles = action.payload;
    });
    builder.addCase(billingCyclesGet.rejected, (state) => {
      state.billingsCycleGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(waterReadingsGet.pending, (state) => {
      state.waterReadingsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(waterReadingsGet.fulfilled, (state, action) => {
      state.waterReadingsGetStatus = ProcessingStatus.SUCCEEDED;
      state.waterReadings = action.payload;
    });
    builder.addCase(waterReadingsGet.rejected, (state) => {
      state.waterReadingsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(waterReadingsAllGet.pending, (state) => {
      state.waterReadingsAllGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(waterReadingsAllGet.fulfilled, (state, action) => {
      state.waterReadingsAllGetStatus = ProcessingStatus.SUCCEEDED;
      state.waterReadingsAll = action.payload;
    });
    builder.addCase(waterReadingsAllGet.rejected, (state) => {
      state.waterReadingsAllGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(waterReadingsPost.pending, (state) => {
      state.waterReadingsPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(waterReadingsPost.fulfilled, (state, action) => {
      state.waterReadingsPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(waterReadingsPost.rejected, (state) => {
      state.waterReadingsPostStatus = ProcessingStatus.FAILED;
    });
  },
});

export const billingsReducer = billingsSlice.reducer;
export const { clearState, clearStatus } = billingsSlice.actions;

export const selectBillingsGetStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.billingsGetStatus;

export const selectWaterReadingsAllStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.waterReadingsAllGetStatus;

export const selectWaterReadingsGetStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.waterReadingsGetStatus;

export const selectWaterReadingsPostStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.waterReadingsPostStatus;

export const selectBillingCycleGetStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.billingsCycleGetStatus;

export const selectBillingPutStatus = (
  state: BillingsSlice
): ProcessingStatus => state.billings.billingsPutStatus;

export const selectBillings = (state: BillingsSlice): Billing | undefined =>
  state.billings.billings;

export const selectWaterReadings = (
  state: BillingsSlice
): IWaterReadings | undefined => state.billings.waterReadings;

export const selectBillingCycles = (state: BillingsSlice): Cycle[] =>
  state.billings.billingCycles;

export const selectWaterReadingsAll = (
  state: BillingsSlice
): ApiWaterReadings | undefined => state.billings.waterReadingsAll;
