import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Unit,
  ApiUnits,
  ParamsRole,
  TenantAssignUnit,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  selectUnit,
  selectUnits,
  resetStatusState,
  selectUnitPutStatus,
  selectUnitGetStatus,
  selectUnitPostStatus,
  selectUnitDropStatus,
  selectAssignUnitStatus,
} from './slice';
import {
  unitDropUser,
  unitAssignUser,
  unitGetRequest,
  unitPutRequest,
  unitsGetRequest,
  unitPostRequest,
  unitsNoPagGetRequest,
  unitsByUserGetRequest,
  unitDeclareVacantRequest,
  unitsByPropertyGetRequest,
} from './actions';

export interface UseUnits {
  unit: Unit | undefined;
  units: ApiUnits | undefined;
  unitPutStatus: ProcessingStatus;
  unitGetStatus: ProcessingStatus;
  unitPostStatus: ProcessingStatus;
  assignUnitStatus: ProcessingStatus;
  unitDropUserStatus: ProcessingStatus;

  resetStatus: () => void;
  fetchUnits: (role: Roles) => void;
  createUnit: (params: {
    parking: string;
    bedrooms: string;
    amount: number | string;
    role: Roles;
    name: string;
    description: string;
    bathrooms: string;
    internet: string;
    property_id: string;
  }) => void;
  updateUnit: (params: Unit) => void;
  fetchUnit: (param: ParamsRole) => void;
  fetchUnitsNoPag: (role: Roles) => void;
  unitDropUsers: (param: ParamsRole) => void;
  fetchUnitsByUser: (param: ParamsRole) => void;
  assignUnit: (params: TenantAssignUnit) => void;
  unitDeclareVacant: (param: ParamsRole) => void;
  fetchUnitsByProperty: (param: ParamsRole) => void;
}

export function useUnits(): UseUnits {
  const dispatch = useDispatch();
  const unit = useSelector(selectUnit);
  const units = useSelector(selectUnits);
  const unitGetStatus = useSelector(selectUnitGetStatus);
  const unitPutStatus = useSelector(selectUnitPutStatus);
  const unitPostStatus = useSelector(selectUnitPostStatus);
  const assignUnitStatus = useSelector(selectAssignUnitStatus);
  const unitDropUserStatus = useSelector(selectUnitDropStatus);

  const resetStatus = useCallback(() => {
    dispatch(resetStatusState());
  }, [dispatch]);

  const fetchUnits = useCallback(
    (role: Roles) => {
      dispatch(unitsGetRequest(role));
    },
    [dispatch]
  );

  const fetchUnitsNoPag = useCallback(
    (role: Roles) => {
      dispatch(unitsNoPagGetRequest(role));
    },
    [dispatch]
  );

  const fetchUnitsByUser = useCallback(
    (param: ParamsRole) => {
      dispatch(unitsByUserGetRequest(param));
    },
    [dispatch]
  );

  const fetchUnitsByProperty = useCallback(
    (param: ParamsRole) => {
      dispatch(unitsByPropertyGetRequest(param));
    },
    [dispatch]
  );

  const fetchUnit = useCallback(
    (param: ParamsRole) => {
      dispatch(unitGetRequest(param));
    },
    [dispatch]
  );

  const unitDeclareVacant = useCallback(
    (param: ParamsRole) => {
      dispatch(unitDeclareVacantRequest(param));
    },
    [dispatch]
  );

  const createUnit = useCallback(
    (params: Unit) => {
      dispatch(unitPostRequest(params));
    },
    [dispatch]
  );

  const updateUnit = useCallback(
    (params: Unit) => {
      dispatch(unitPutRequest(params));
    },
    [dispatch]
  );

  const assignUnit = useCallback(
    (params: TenantAssignUnit) => {
      dispatch(unitAssignUser(params));
    },
    [dispatch]
  );

  const unitDropUsers = useCallback(
    (param: ParamsRole) => {
      dispatch(unitDropUser(param));
    },
    [dispatch]
  );

  return {
    unit,
    units,
    fetchUnit,
    fetchUnits,
    updateUnit,
    createUnit,
    assignUnit,
    resetStatus,
    unitGetStatus,
    unitPutStatus,
    unitDropUsers,
    unitPostStatus,
    fetchUnitsNoPag,
    assignUnitStatus,
    fetchUnitsByUser,
    unitDeclareVacant,
    unitDropUserStatus,
    fetchUnitsByProperty,
  };
}
