import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';

import { paymentsPut } from './actions';

interface PaymentsState {
  paymentsPutStatus: ProcessingStatus;
}

export const initialState: PaymentsState = {
  paymentsPutStatus: ProcessingStatus.IDLE,
};

interface PaymentsSlice {
  payments: PaymentsState;
}

export const paymentsSlice = createSlice({
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.paymentsPutStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'payments',
  extraReducers: (builder) => {
    builder.addCase(paymentsPut.pending, (state) => {
      state.paymentsPutStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(paymentsPut.fulfilled, (state, action) => {
      state.paymentsPutStatus = ProcessingStatus.SUCCEEDED;
    });

    builder.addCase(paymentsPut.rejected, (state) => {
      state.paymentsPutStatus = ProcessingStatus.FAILED;
    });
  },
});

export const paymentsReducer = paymentsSlice.reducer;
export const { resetStatus } = paymentsSlice.actions;

export const selectPutStatus = (state: PaymentsSlice): ProcessingStatus =>
  state.payments.paymentsPutStatus;
