import moment from 'moment';

export function formatDigit(digit: string | number) {
  return (
    'Ksh ' +
    new Intl.NumberFormat('en-KE', {
      maximumSignificantDigits: 3,
    }).format(Number(digit))
  );
}

export function formatTime(time: string) {
  return moment(time).format('ll');
}

export function formatTimeExtended(time: string) {
  return moment(time).format('lll');
}

export function formatDateToHyphen(date: string | undefined) {
  if (!date || typeof date !== 'string') {
    return undefined;
  }

  const parts = date.split('/');
  if (parts.length !== 3) {
    return undefined;
  }

  const formattedDate = parts.join('-');
  return formattedDate;
}

export function currentCycle(revert?: string) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const splitter = revert ? revert : '-';

  // Get the last day of the current month
  const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Formatting to MM-DD-YYYY
  const formattedLastDay = `${(currentMonth + 1)
    .toString()
    .padStart(2, '0')}${splitter}${lastDay
    .toString()
    .padStart(2, '0')}${splitter}${currentYear}`;

  return formattedLastDay;
}

export function cycleMonthYear(date: string) {
  const parts = date.split('-');

  const month = parts[0];
  const year = parts[2];

  return `${month}-${year}`;
}
