import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';
import { ApiBill, PropertyBills } from '@real-estate-portal/interfaces';

import {
  billPut,
  billGet,
  billPost,
  billsGet,
  billAssign,
  billDelete,
} from './actions';

interface BillsState {
  bills: ApiBill | undefined;
  propertyBills: PropertyBills[];
  billPutStatus: ProcessingStatus;
  billPostStatus: ProcessingStatus;
  billsGetStatus: ProcessingStatus;
  billDeleteStatus: ProcessingStatus;
  billAssignStatus: ProcessingStatus;
}

export const initialState: BillsState = {
  bills: undefined,
  propertyBills: [],
  billPutStatus: ProcessingStatus.IDLE,
  billPostStatus: ProcessingStatus.IDLE,
  billsGetStatus: ProcessingStatus.IDLE,
  billDeleteStatus: ProcessingStatus.IDLE,
  billAssignStatus: ProcessingStatus.IDLE,
};

interface BillsSlice {
  bills: BillsState;
}

export const billsSlice = createSlice({
  initialState,
  name: 'bills',
  reducers: {
    resetStatusStates: (state) => {
      state.billPutStatus = ProcessingStatus.IDLE;
      state.billPostStatus = ProcessingStatus.IDLE;
      state.billsGetStatus = ProcessingStatus.IDLE;
      state.billDeleteStatus = ProcessingStatus.IDLE;
      state.billAssignStatus = ProcessingStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(billsGet.pending, (state) => {
      state.billsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billsGet.fulfilled, (state, action) => {
      state.billsGetStatus = ProcessingStatus.SUCCEEDED;
      state.bills = action.payload;
    });
    builder.addCase(billsGet.rejected, (state) => {
      state.billsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billGet.pending, (state) => {
      state.billsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billGet.fulfilled, (state, action) => {
      state.billsGetStatus = ProcessingStatus.SUCCEEDED;
      state.propertyBills = action.payload;
    });
    builder.addCase(billGet.rejected, (state) => {
      state.billsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billDelete.pending, (state) => {
      state.billDeleteStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billDelete.fulfilled, (state, action) => {
      state.billDeleteStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(billDelete.rejected, (state) => {
      state.billDeleteStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billAssign.pending, (state) => {
      state.billAssignStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billAssign.fulfilled, (state, action) => {
      state.billAssignStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(billAssign.rejected, (state) => {
      state.billAssignStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billPut.pending, (state) => {
      state.billPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billPut.fulfilled, (state, action) => {
      state.billPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(billPut.rejected, (state) => {
      state.billPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billPost.pending, (state) => {
      state.billPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(billPost.fulfilled, (state, action) => {
      state.billPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(billPost.rejected, (state) => {
      state.billPostStatus = ProcessingStatus.FAILED;
    });
  },
});

export const billsReducer = billsSlice.reducer;
export const { resetStatusStates } = billsSlice.actions;

export const selectBillsGetStatus = (state: BillsSlice): ProcessingStatus =>
  state.bills.billsGetStatus;

export const selectBillDeleteStatus = (state: BillsSlice): ProcessingStatus =>
  state.bills.billDeleteStatus;

export const selectBillPostStatus = (state: BillsSlice): ProcessingStatus =>
  state.bills.billPostStatus;

export const selectBillPutStatus = (state: BillsSlice): ProcessingStatus =>
  state.bills.billPutStatus;

export const selectBillAssignStatus = (state: BillsSlice): ProcessingStatus =>
  state.bills.billAssignStatus;

export const selectBills = (state: BillsSlice): ApiBill | undefined =>
  state.bills.bills;

export const selectPropertyBills = (state: BillsSlice): PropertyBills[] =>
  state.bills.propertyBills;
