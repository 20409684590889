import { TransactionsStatus as Status } from '@real-estate-portal/enums';

type ReturnedStatus = {
  label: string;
  color: 'default' | 'primary' | 'secondary';
};

export const getStatus = (status: Status): ReturnedStatus => {
  if (status === Status.PENDING) {
    return { label: 'Pending', color: 'default' };
  }

  if (status === Status.COMPLETED) {
    return { label: 'Completed', color: 'primary' };
  }

  if (status === Status.FAILED) {
    return { label: 'Failed', color: 'secondary' };
  }

  return { label: 'Pending', color: 'primary' };
};
