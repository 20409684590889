import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callGet,
  callPost,
  callPut,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  ParamsRole,
  RespStatus,
  LipaNaMpesa,
  ApiTransaction,
  LipaNaMpesaForm,
  TransactionPayload,
} from '@real-estate-portal/interfaces';
import { Roles } from '@real-estate-portal/enums';

export const transactionsGet = createAsyncThunk(
  'transactions/all',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<ApiTransaction>(
        getHttpEndpoint(role).transactionsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const transactionsGetByUnit = createAsyncThunk(
  'transactions/per-unit',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiTransaction>(
        getHttpEndpoint(role, `/${id}${params}`).transactionsByUnitGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const transactionGetByUser = createAsyncThunk(
  'transactions/per-user',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiTransaction>(
        getHttpEndpoint(role, `/${id}${params}`).transactionsByUserGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const transactionPost = createAsyncThunk(
  'transactions/create',
  async (payload: TransactionPayload, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, TransactionPayload>(
        getHttpEndpoint(payload.role).transactionPost ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const transactionPut = createAsyncThunk(
  'transactions/update',
  async (payload: TransactionPayload, thunkAPI) => {
    try {
      const { data } = await callPut<RespStatus, TransactionPayload>(
        getHttpEndpoint(payload.role, `/${payload.uuid}`).transactionPut ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const lipaNaMpesaPost = createAsyncThunk(
  'transactions/lipa-na-mpesa',
  async (payload: LipaNaMpesaForm, thunkAPI) => {
    try {
      const { data } = await callPost<LipaNaMpesa, LipaNaMpesaForm>(
        getHttpEndpoint(payload.role).lipaNameMpesaPost ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
