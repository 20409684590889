import { createSlice } from '@reduxjs/toolkit';
import { Documents } from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import { documentsGet, documentDelete, documentPost } from './actions';

interface DocumentsState {
  documents: Documents[] | undefined;
  documentsGetStatus: ProcessingStatus;
  documentPostStatus: ProcessingStatus;
  documentDeleteStatus: ProcessingStatus;
}

export const initialState: DocumentsState = {
  documents: undefined,
  documentPostStatus: ProcessingStatus.IDLE,
  documentsGetStatus: ProcessingStatus.IDLE,
  documentDeleteStatus: ProcessingStatus.IDLE,
};

interface DocumentsSlice {
  documents: DocumentsState;
}

export const documentsSlice = createSlice({
  initialState,
  name: 'documents',
  reducers: {
    resetStatusStates: (state) => {
      state.documentPostStatus = ProcessingStatus.IDLE;
      state.documentsGetStatus = ProcessingStatus.IDLE;
      state.documentDeleteStatus = ProcessingStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(documentsGet.pending, (state) => {
      state.documentsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(documentsGet.fulfilled, (state, action) => {
      state.documentsGetStatus = ProcessingStatus.SUCCEEDED;
      state.documents = action.payload;
    });
    builder.addCase(documentsGet.rejected, (state) => {
      state.documentsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(documentDelete.pending, (state) => {
      state.documentDeleteStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(documentDelete.fulfilled, (state, action) => {
      state.documentDeleteStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(documentDelete.rejected, (state) => {
      state.documentDeleteStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(documentPost.pending, (state) => {
      state.documentPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(documentPost.fulfilled, (state, action) => {
      state.documentPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(documentPost.rejected, (state) => {
      state.documentPostStatus = ProcessingStatus.FAILED;
    });
  },
});

export const documentsReducer = documentsSlice.reducer;
export const { resetStatusStates } = documentsSlice.actions;

export const selectDocumentsGetStatus = (
  state: DocumentsSlice
): ProcessingStatus => state.documents.documentsGetStatus;

export const selectDocumentDeleteStatus = (
  state: DocumentsSlice
): ProcessingStatus => state.documents.documentDeleteStatus;

export const selectDocumentPostStatus = (
  state: DocumentsSlice
): ProcessingStatus => state.documents.documentPostStatus;

export const selectDocuments = (
  state: DocumentsSlice
): Documents[] | undefined => state.documents.documents;
