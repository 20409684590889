import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';
import { ApiProperties, Properties } from '@real-estate-portal/interfaces';

import {
  propertyGet,
  propertyPut,
  propertyPost,
  propertiesGet,
  propertyDelete,
} from './actions';

interface PropertiesState {
  property: Properties | undefined;
  properties: ApiProperties | undefined;

  propertyPutStatus: ProcessingStatus;
  propertyGetStatus: ProcessingStatus;
  propertyPostStatus: ProcessingStatus;
  propertiesGetStatus: ProcessingStatus;
  propertyDeleteStatus: ProcessingStatus;
}

export const initialState: PropertiesState = {
  property: undefined,
  properties: undefined,

  propertyPutStatus: ProcessingStatus.IDLE,
  propertyGetStatus: ProcessingStatus.IDLE,
  propertyPostStatus: ProcessingStatus.IDLE,
  propertiesGetStatus: ProcessingStatus.IDLE,
  propertyDeleteStatus: ProcessingStatus.IDLE,
};

interface PropertiesSlice {
  property: PropertiesState;
}

export const propertiesSlice = createSlice({
  initialState,
  reducers: {
    resetDataStates: (state) => {
      state.property = undefined;
      state.properties = undefined;
    },
    resetStatusStates: (state) => {
      state.propertyPutStatus = ProcessingStatus.IDLE;
      state.propertyGetStatus = ProcessingStatus.IDLE;
      state.propertyPostStatus = ProcessingStatus.IDLE;
      state.propertiesGetStatus = ProcessingStatus.IDLE;
      state.propertyDeleteStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'properties',
  extraReducers: (builder) => {
    builder.addCase(propertiesGet.pending, (state) => {
      state.propertiesGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(propertiesGet.fulfilled, (state, { payload }) => {
      state.propertiesGetStatus = ProcessingStatus.SUCCEEDED;
      state.properties = payload;
    });

    builder.addCase(propertiesGet.rejected, (state) => {
      state.propertiesGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(propertyGet.pending, (state) => {
      state.propertyGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(propertyGet.fulfilled, (state, { payload }) => {
      state.propertyGetStatus = ProcessingStatus.SUCCEEDED;
      state.property = payload;
    });

    builder.addCase(propertyGet.rejected, (state) => {
      state.propertyGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(propertyPost.pending, (state) => {
      state.propertyPostStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(propertyPost.fulfilled, (state, { payload }) => {
      state.propertyPostStatus = ProcessingStatus.SUCCEEDED;
    });

    builder.addCase(propertyPost.rejected, (state) => {
      state.propertyPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(propertyPut.pending, (state) => {
      state.propertyPutStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(propertyPut.fulfilled, (state) => {
      state.propertyPutStatus = ProcessingStatus.SUCCEEDED;
    });

    builder.addCase(propertyPut.rejected, (state) => {
      state.propertyPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(propertyDelete.pending, (state) => {
      state.propertyDeleteStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(propertyDelete.fulfilled, (state) => {
      state.propertyDeleteStatus = ProcessingStatus.SUCCEEDED;
    });

    builder.addCase(propertyDelete.rejected, (state) => {
      state.propertyDeleteStatus = ProcessingStatus.FAILED;
    });
  },
});

export const propertiesReducer = propertiesSlice.reducer;
export const { resetStatusStates, resetDataStates } = propertiesSlice.actions;

export const selectPropertyPutStatus = (
  state: PropertiesSlice
): ProcessingStatus => state.property.propertyPutStatus;

export const selectPropertyPostStatus = (
  state: PropertiesSlice
): ProcessingStatus => state.property.propertyPostStatus;

export const selectPropertyDeleteStatus = (
  state: PropertiesSlice
): ProcessingStatus => state.property.propertyDeleteStatus;

export const selectPropertyGetStatus = (
  state: PropertiesSlice
): ProcessingStatus => state.property.propertyGetStatus;

export const selectPropertiesGetStatus = (
  state: PropertiesSlice
): ProcessingStatus => state.property.propertiesGetStatus;

export const selectProperties = (
  state: PropertiesSlice
): ApiProperties | undefined => state.property.properties;

export const selectProperty = (
  state: PropertiesSlice
): Properties | undefined => state.property.property;
