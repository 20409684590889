import jwt_decode from 'jwt-decode';
import { getUserToken } from './get-stored-data';

interface DecodedToken {
  exp: string;
  iat: string;
  iss: string;
  jti: string;
  nbf: string;
  prv: string;
  sub: string;
}

export function getUserId(): string {
  const token = getUserToken() as string;
  if (!token) return '';

  const decodeToken: DecodedToken = jwt_decode(token);
  return decodeToken.sub;
}
