import { makeStyles } from '@material-ui/core/styles';
import { secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() => ({
  phoneInput: {
    height: 36,
    fontSize: '0.8rem',
    borderRadius: '4px',
    paddingLeft: '14px',
    backgroundColor: 'transparent',
    border: `1px solid ${secondaryColors.grey.grey003}`,
  },
  inputIcons: {
    marginTop: -2,
    marginRight: 12,
  },
  endInputIcon: {
    cursor: 'pointer',
  },
  formLabel: {
    marginTop: 10,
    color: 'black',
    fontSize: '0.8125rem',
  },
  errorText: {
    fontSize: '0.7rem',
  },
}));

export default useStyles;
