import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  spacing: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      maxWidth: 800,
      margin: ({ spacing }) => `${spacing}% auto`,
    },
  })
);

interface Props {
  children: React.ReactNode;
  spacing?: number;
}

export function BodyWrapper({ children, spacing = 3 }: Props): JSX.Element {
  const { root } = useStyles({ spacing });

  return <section className={root}>{children}</section>;
}
