import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  LoginForm,
  RegisterForm,
  PasswordReset,
  LoginResponse,
  ForgotPassword,
  RegisterResponse,
  PasswordResetResp,
} from '@real-estate-portal/interfaces';
import { callPost, getHttpEndpoint } from '@real-estate-portal/utils';

export const loginPost = createAsyncThunk(
  'auth/login',
  async (param: LoginForm, thunkAPI) => {
    try {
      const { data } = await callPost<LoginResponse, LoginForm>(
        getHttpEndpoint(param.role).login,
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const registerPost = createAsyncThunk(
  'auth/register',
  async (param: RegisterForm, thunkAPI) => {
    try {
      const { data } = await callPost<RegisterResponse, RegisterForm>(
        getHttpEndpoint(param.role).register ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const forgotPasswordPost = createAsyncThunk(
  'auth/forgot-password',
  async (param: ForgotPassword, thunkAPI) => {
    try {
      const { data } = await callPost<PasswordResetResp, ForgotPassword>(
        getHttpEndpoint(param.role).forgotPassword ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const resetPasswordPost = createAsyncThunk(
  'auth/reset-password',
  async (param: PasswordReset, thunkAPI) => {
    try {
      const { data } = await callPost<PasswordResetResp, PasswordReset>(
        getHttpEndpoint(param.role).resetPassword ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
