import { getCookie } from './cookies';
import { decrypt } from './security';
import {
  EXPIRATION_DATE,
  TOKEN_STORAGE_NAME,
} from '@real-estate-portal/constants';

export function getStoredData(key: string): unknown {
  const encData = getCookie(key);

  if (!encData) {
    return encData;
  }

  const decryptedData = decrypt(encData);

  if (typeof decryptedData !== undefined) {
    return decryptedData;
  }

  return '';
}

export function getUserToken(): string | unknown {
  return getStoredData(TOKEN_STORAGE_NAME ?? '');
}

export function isTokenExpired(): boolean {
  const storedExpirationDate = getStoredData(EXPIRATION_DATE) as string;

  if (storedExpirationDate) {
    const expirationDateTime = new Date(storedExpirationDate);
    const currentDate = new Date();

    return currentDate > expirationDateTime;
  }

  return true;
}
