import { createAsyncThunk } from '@reduxjs/toolkit';
import { callPut, getHttpEndpoint } from '@real-estate-portal/utils';

import { PaymentForm, RespStatus } from '@real-estate-portal/interfaces';

export const paymentsPut = createAsyncThunk(
  'payments/update',
  async (payload: PaymentForm, thunkAPI) => {
    try {
      const { uuid, ...rest } = payload;
      const { data } = await callPut<RespStatus, PaymentForm>(
        getHttpEndpoint(rest.role, `/${uuid}`).paymentPut ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
