import { createSlice } from '@reduxjs/toolkit';

import {
  User,
  ApiUser,
  ApiCompany,
  ApiTempAdmins,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  userGet,
  userPut,
  adminsGet,
  logoutPost,
  passwordPut,
  companyPost,
  adminDelete,
  companiesGet,
  tempAdminsGet,
  changeAdminState,
} from './actions';

interface AccountState {
  role: Roles;
  user: User | undefined;
  admins: ApiUser | undefined;
  logoutStatus: ProcessingStatus;
  userGetStatus: ProcessingStatus;
  userPutStatus: ProcessingStatus;
  adminsGetStatus: ProcessingStatus;
  companies: ApiCompany | undefined;
  companyPostStatus: ProcessingStatus;
  passwordPutStatus: ProcessingStatus;
  userAdminGetStatus: ProcessingStatus;
  companiesGetStatus: ProcessingStatus;
  tempAdminsGetStatus: ProcessingStatus;
  tempAdmins: ApiTempAdmins | undefined;
  changeAdminStateStatus: ProcessingStatus;
  deleteAdminStateStatus: ProcessingStatus;
}

export const initialState: AccountState = {
  user: undefined,
  admins: undefined,
  role: Roles.TENANT,
  companies: undefined,
  tempAdmins: undefined,
  logoutStatus: ProcessingStatus.IDLE,
  userGetStatus: ProcessingStatus.IDLE,
  userPutStatus: ProcessingStatus.IDLE,
  adminsGetStatus: ProcessingStatus.IDLE,
  passwordPutStatus: ProcessingStatus.IDLE,
  companyPostStatus: ProcessingStatus.IDLE,
  userAdminGetStatus: ProcessingStatus.IDLE,
  companiesGetStatus: ProcessingStatus.IDLE,
  tempAdminsGetStatus: ProcessingStatus.IDLE,
  changeAdminStateStatus: ProcessingStatus.IDLE,
  deleteAdminStateStatus: ProcessingStatus.IDLE,
};

interface AccountSlice {
  account: AccountState;
}

export const accountSlice = createSlice({
  initialState,
  reducers: {
    resetStatusStates: (state) => {
      state.logoutStatus = ProcessingStatus.IDLE;
      state.userGetStatus = ProcessingStatus.IDLE;
      state.userPutStatus = ProcessingStatus.IDLE;
      state.adminsGetStatus = ProcessingStatus.IDLE;
      state.companyPostStatus = ProcessingStatus.IDLE;
      state.passwordPutStatus = ProcessingStatus.IDLE;
      state.companiesGetStatus = ProcessingStatus.IDLE;
      state.userAdminGetStatus = ProcessingStatus.IDLE;
      state.tempAdminsGetStatus = ProcessingStatus.IDLE;
      state.changeAdminStateStatus = ProcessingStatus.IDLE;
      state.deleteAdminStateStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'account',
  extraReducers: (builder) => {
    builder.addCase(userGet.pending, (state) => {
      state.userGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(userGet.fulfilled, (state, { payload }) => {
      state.userGetStatus = ProcessingStatus.SUCCEEDED;
      state.user = payload;
      // state.role = payload.role;
    });
    builder.addCase(userGet.rejected, (state) => {
      state.userGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(companiesGet.pending, (state) => {
      state.companiesGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(companiesGet.fulfilled, (state, { payload }) => {
      state.companiesGetStatus = ProcessingStatus.SUCCEEDED;
      state.companies = payload;
    });
    builder.addCase(companiesGet.rejected, (state) => {
      state.companiesGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(tempAdminsGet.pending, (state) => {
      state.tempAdminsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(tempAdminsGet.fulfilled, (state, { payload }) => {
      state.tempAdminsGetStatus = ProcessingStatus.SUCCEEDED;
      state.tempAdmins = payload;
    });
    builder.addCase(tempAdminsGet.rejected, (state) => {
      state.tempAdminsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(companyPost.pending, (state) => {
      state.companyPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(companyPost.fulfilled, (state) => {
      state.companyPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(companyPost.rejected, (state) => {
      state.companyPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(adminsGet.pending, (state) => {
      state.adminsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(adminsGet.fulfilled, (state, { payload }) => {
      state.adminsGetStatus = ProcessingStatus.SUCCEEDED;
      state.admins = payload;
    });
    builder.addCase(adminsGet.rejected, (state) => {
      state.adminsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(adminDelete.pending, (state) => {
      state.deleteAdminStateStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(adminDelete.fulfilled, (state) => {
      state.deleteAdminStateStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(adminDelete.rejected, (state) => {
      state.deleteAdminStateStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(userPut.pending, (state) => {
      state.userPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(userPut.fulfilled, (state) => {
      state.userPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(userPut.rejected, (state) => {
      state.userPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(passwordPut.pending, (state) => {
      state.passwordPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(passwordPut.fulfilled, (state) => {
      state.passwordPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(passwordPut.rejected, (state) => {
      state.passwordPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(changeAdminState.pending, (state) => {
      state.changeAdminStateStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(changeAdminState.fulfilled, (state) => {
      state.changeAdminStateStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(changeAdminState.rejected, (state) => {
      state.changeAdminStateStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(logoutPost.pending, (state) => {
      state.logoutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(logoutPost.fulfilled, (state) => {
      state.logoutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(logoutPost.rejected, (state) => {
      state.logoutStatus = ProcessingStatus.FAILED;
    });
  },
});

export const accountReducer = accountSlice.reducer;
export const { resetStatusStates } = accountSlice.actions;

export const selectLogoutStatus = (state: AccountSlice): ProcessingStatus =>
  state.account.logoutStatus;

export const selectChangeAdminStateStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.changeAdminStateStatus;

export const selectAdminsGetStatus = (state: AccountSlice): ProcessingStatus =>
  state.account.adminsGetStatus;

export const selectTempAdminsGetStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.tempAdminsGetStatus;

export const selectUserAdminsGetStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.userAdminGetStatus;

export const selectCompaniesGetStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.companiesGetStatus;

export const selectCompanies = (state: AccountSlice): ApiCompany | undefined =>
  state.account.companies;

export const selectCompanyPostStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.companyPostStatus;

export const selectUserGetStatus = (state: AccountSlice): ProcessingStatus =>
  state.account.userGetStatus;

export const selectUserPutStatus = (state: AccountSlice): ProcessingStatus =>
  state.account.userPutStatus;

export const selectDeleteAdminStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.deleteAdminStateStatus;

export const selectPasswordPutStatus = (
  state: AccountSlice
): ProcessingStatus => state.account.passwordPutStatus;

export const selectUser = (state: AccountSlice): User | undefined =>
  state.account.user;

export const selectAdmins = (state: AccountSlice): ApiUser | undefined =>
  state.account.admins;

export const selectTempAdmins = (
  state: AccountSlice
): ApiTempAdmins | undefined => state.account.tempAdmins;

export const selectRole = (state: AccountSlice): Roles => state.account.role;
