import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Transaction } from '@real-estate-portal/interfaces';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

export const useStyles = makeStyles({
  printTitle: {
    fontSize: '0.75rem',
    color: secondaryColors.darkGrey,
  },
  printValue: {
    fontSize: '1rem',
    color: primaryColors.blue,
  },
  printDetailsWrapper: {
    marginBottom: 10,
  },
});

interface Props {
  transaction: Transaction;
}

export function TransactionModal({ transaction }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <div style={{ marginBottom: '50px' }} />
      <Grid container spacing={1}>
        <Grid item md={12}>
          <div className={classes.printDetailsWrapper}>
            <Typography className={classes.printTitle}>Note</Typography>
            <Typography className={classes.printValue}>
              {transaction.note}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default TransactionModal;
