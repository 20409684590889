import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ParamsRole,
  Properties,
  PropertyForm,
  ApiProperties,
  PropertyUpdateForm,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  propertyGet,
  propertyPut,
  propertyPost,
  propertiesGet,
  propertyDelete,
} from './actions';
import {
  selectProperty,
  resetDataStates,
  selectProperties,
  resetStatusStates,
  selectPropertyGetStatus,
  selectPropertyPutStatus,
  selectPropertyPostStatus,
  selectPropertiesGetStatus,
  selectPropertyDeleteStatus,
} from './slice';

interface UseProperties {
  property: Properties | undefined;
  properties: ApiProperties | undefined;

  propertyGetStatus: ProcessingStatus;
  propertyPutStatus: ProcessingStatus;
  propertyPostStatus: ProcessingStatus;
  propertiesGetStatus: ProcessingStatus;
  propertyDeleteStatus: ProcessingStatus;

  resetStates: () => void;
  resetStatus: () => void;
  fetchProperty: (param: ParamsRole) => void;
  deleteProperty: (param: ParamsRole) => void;
  fetchProperties: (params: ParamsRole) => void;
  createProperty: (params: PropertyForm) => void;
  updateProperty: (params: PropertyUpdateForm) => void;
}

export function useProperties(): UseProperties {
  const dispatch = useDispatch();
  const property = useSelector(selectProperty);
  const properties = useSelector(selectProperties);

  const propertyGetStatus = useSelector(selectPropertyGetStatus);
  const propertyPutStatus = useSelector(selectPropertyPutStatus);
  const propertyPostStatus = useSelector(selectPropertyPostStatus);
  const propertiesGetStatus = useSelector(selectPropertiesGetStatus);
  const propertyDeleteStatus = useSelector(selectPropertyDeleteStatus);

  const resetStates = useCallback(() => {
    dispatch(resetDataStates());
  }, [dispatch]);

  const resetStatus = useCallback(() => {
    dispatch(resetStatusStates());
  }, [dispatch]);

  const fetchProperties = useCallback(
    (param: ParamsRole) => {
      dispatch(propertiesGet(param));
    },
    [dispatch]
  );

  const fetchProperty = useCallback(
    (param: ParamsRole) => {
      dispatch(propertyGet(param));
    },
    [dispatch]
  );

  const createProperty = useCallback(
    (params: PropertyForm) => {
      dispatch(propertyPost(params));
    },
    [dispatch]
  );

  const updateProperty = useCallback(
    (params: PropertyUpdateForm) => {
      dispatch(propertyPut(params));
    },
    [dispatch]
  );

  const deleteProperty = useCallback(
    (param: ParamsRole) => {
      dispatch(propertyDelete(param));
    },
    [dispatch]
  );

  return {
    property,
    properties,
    resetStates,
    resetStatus,
    fetchProperty,
    updateProperty,
    createProperty,
    deleteProperty,
    fetchProperties,
    propertyGetStatus,
    propertyPutStatus,
    propertyPostStatus,
    propertiesGetStatus,
    propertyDeleteStatus,
  };
}
