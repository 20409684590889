import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callGet,
  callPut,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  Tenant,
  ApiTenant,
  ParamsRole,
  RegisterForm,
  TenantPayload,
  ApiTenantToUnit,
  RegisterResponse,
  TenantDelResponse,
} from '@real-estate-portal/interfaces';

export const tenantsGet = createAsyncThunk(
  'tenants/all',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { params, role } = param;
      const { data } = await callGet<ApiTenant>(
        getHttpEndpoint(role, `${params}`).tenantsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const tenantsPerUnitGet = createAsyncThunk(
  'tenants/per/unit',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiTenantToUnit>(
        getHttpEndpoint(role, `/${id}${params}`).tenantsByUnitGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const tenantsPerPropertyGet = createAsyncThunk(
  'tenants/per/property',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = param;
      const { data } = await callGet<ApiTenant>(
        getHttpEndpoint(role, `/${id}${params}`).tenantsByPropertyGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const tenantGet = createAsyncThunk(
  'tenants/tenant',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callGet<Tenant>(
        getHttpEndpoint(role, `/${id}`).tenantGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const tenantPost = createAsyncThunk(
  'tenants/create',
  async (param: RegisterForm, thunkAPI) => {
    try {
      const { data } = await callPost<RegisterResponse, RegisterForm>(
        getHttpEndpoint(param.role).tenantPost ?? '',
        param
      );

      if (!data) {
        return thunkAPI.rejectWithValue({ errors: ['error'] });
      }

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const tenantPut = createAsyncThunk(
  'tenants/update',
  async (param: TenantPayload, thunkAPI) => {
    try {
      const { data } = await callPut<RegisterResponse, TenantPayload>(
        getHttpEndpoint(param.role, `/${param.uuid}`).tenantPut ?? '',
        param
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const tenantDelete = createAsyncThunk(
  'tenants/delete',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callDelete<TenantDelResponse>(
        getHttpEndpoint(role, `/${id}`).tenantDelete ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);
