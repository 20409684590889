import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  marginLeft: number;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    flexGrow: 1,
  },
  selectField: {
    width: '170px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  btnText: {
    right: 20,
    cursor: 'pointer',
    position: 'absolute',
    textTransform: 'unset',
    textDecoration: 'underline',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cycleInputWrapper: {
    marginLeft: ({ marginLeft }) => marginLeft,
  },
}));
