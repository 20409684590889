import { createSlice } from '@reduxjs/toolkit';

import {
  unitDropUser,
  unitAssignUser,
  unitGetRequest,
  unitPutRequest,
  unitsGetRequest,
  unitPostRequest,
  unitsNoPagGetRequest,
  unitsByUserGetRequest,
  unitDeclareVacantRequest,
  unitsByPropertyGetRequest,
} from './actions';
import {
  Unit,
  ApiUnits,
  PaginationMetadata,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

interface UnitsState {
  unit: Unit | undefined;
  units: ApiUnits | undefined;

  unitPutStatus: ProcessingStatus;
  unitGetStatus: ProcessingStatus;
  unitPostStatus: ProcessingStatus;
  unitAssignStatus: ProcessingStatus;
  unitDropUserStatus: ProcessingStatus;
}

export const initialState: UnitsState = {
  unit: undefined,
  units: undefined,

  unitPutStatus: ProcessingStatus.IDLE,
  unitGetStatus: ProcessingStatus.IDLE,
  unitPostStatus: ProcessingStatus.IDLE,
  unitAssignStatus: ProcessingStatus.IDLE,
  unitDropUserStatus: ProcessingStatus.IDLE,
};

interface UnitsSlice {
  units: UnitsState;
}

export const unitsSlice = createSlice({
  initialState,
  reducers: {
    resetStatusState: (state) => {
      state.unitPutStatus = ProcessingStatus.IDLE;
      state.unitGetStatus = ProcessingStatus.IDLE;
      state.unitPostStatus = ProcessingStatus.IDLE;
      state.unitAssignStatus = ProcessingStatus.IDLE;
      state.unitDropUserStatus = ProcessingStatus.IDLE;
    },
  },
  name: 'units',
  extraReducers: (builder) => {
    builder.addCase(unitGetRequest.pending, (state) => {
      state.unitGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitGetRequest.fulfilled, (state, { payload }) => {
      state.unitGetStatus = ProcessingStatus.SUCCEEDED;
      state.unit = payload;
    });
    builder.addCase(unitGetRequest.rejected, (state) => {
      state.unitGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitPostRequest.fulfilled, (state, { payload }) => {
      state.unitPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(unitPostRequest.rejected, (state) => {
      state.unitPostStatus = ProcessingStatus.FAILED;
    });
    builder.addCase(unitPostRequest.pending, (state) => {
      state.unitPostStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(unitPutRequest.pending, (state) => {
      state.unitPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitPutRequest.rejected, (state) => {
      state.unitPutStatus = ProcessingStatus.FAILED;
    });
    builder.addCase(unitPutRequest.fulfilled, (state) => {
      state.unitPutStatus = ProcessingStatus.SUCCEEDED;
    });

    builder.addCase(unitAssignUser.pending, (state) => {
      state.unitAssignStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitAssignUser.fulfilled, (state) => {
      state.unitAssignStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(unitAssignUser.rejected, (state) => {
      state.unitAssignStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitDropUser.pending, (state) => {
      state.unitDropUserStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitDropUser.fulfilled, (state) => {
      state.unitDropUserStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(unitDropUser.rejected, (state) => {
      state.unitDropUserStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitsGetRequest.pending, (state) => {
      state.unitGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitsGetRequest.fulfilled, (state, action) => {
      state.unitGetStatus = ProcessingStatus.SUCCEEDED;
      state.units = action.payload;
    });
    builder.addCase(unitsGetRequest.rejected, (state) => {
      state.unitGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitsByUserGetRequest.pending, (state) => {
      state.unitGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitsByUserGetRequest.fulfilled, (state, action) => {
      state.unitGetStatus = ProcessingStatus.SUCCEEDED;
      state.units = action.payload;
    });
    builder.addCase(unitsByUserGetRequest.rejected, (state) => {
      state.unitGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitsNoPagGetRequest.pending, (state) => {
      state.unitGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitsNoPagGetRequest.fulfilled, (state, action) => {
      state.unitGetStatus = ProcessingStatus.SUCCEEDED;
      state.units = { data: action.payload, ...({} as PaginationMetadata) };
    });
    builder.addCase(unitsNoPagGetRequest.rejected, (state) => {
      state.unitGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitsByPropertyGetRequest.pending, (state) => {
      state.unitGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitsByPropertyGetRequest.fulfilled, (state, action) => {
      state.unitGetStatus = ProcessingStatus.SUCCEEDED;
      state.units = action.payload;
    });
    builder.addCase(unitsByPropertyGetRequest.rejected, (state) => {
      state.unitGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(unitDeclareVacantRequest.pending, (state) => {
      state.unitPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(unitDeclareVacantRequest.fulfilled, (state, action) => {
      state.unitPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(unitDeclareVacantRequest.rejected, (state) => {
      state.unitPutStatus = ProcessingStatus.FAILED;
    });
  },
});

export const unitsReducer = unitsSlice.reducer;
export const { resetStatusState } = unitsSlice.actions;

export const selectAssignUnitStatus = (state: UnitsSlice): ProcessingStatus =>
  state.units.unitAssignStatus;

export const selectUnitDropStatus = (state: UnitsSlice): ProcessingStatus =>
  state.units.unitDropUserStatus;

export const selectUnitPostStatus = (state: UnitsSlice): ProcessingStatus =>
  state.units.unitPostStatus;

export const selectUnitPutStatus = (state: UnitsSlice): ProcessingStatus =>
  state.units.unitPutStatus;

export const selectUnitGetStatus = (state: UnitsSlice): ProcessingStatus =>
  state.units.unitGetStatus;

export const selectUnit = (state: UnitsSlice): Unit | undefined =>
  state.units.unit;

export const selectUnits = (state: UnitsSlice): ApiUnits | undefined =>
  state.units.units;
