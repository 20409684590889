import React from 'react';
import Input from 'react-phone-number-input/input';
import { Control, Controller } from 'react-hook-form';
import { Grid, GridSize, Typography, FormControl } from '@material-ui/core';

import useStyles from './styles';

interface Props {
  id: string;
  name: string;
  error: boolean;
  label?: string;
  size?: GridSize;
  htmlFor?: string;
  control?: Control;
  minLength?: number;
  className?: string;
  multiline?: boolean;
  'data-test'?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  defaultValue?: string;
}

export function FormPhoneField(props: Props): JSX.Element {
  const {
    id,
    size,
    name,
    label,
    error,
    htmlFor,
    control,
    minLength,
    defaultValue,
    className = '',
    placeholder = '',
    errorMessage = '',
    isRequired = false,
  } = props;

  const classes = useStyles();

  return (
    <Grid container item md={size} className={className}>
      <FormControl fullWidth>
        {label && (
          <label htmlFor={htmlFor} className={classes.formLabel}>
            {label}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{ required: isRequired, minLength }}
          render={({ name, value, onChange }): JSX.Element => (
            <Input
              id={id}
              name={name}
              country="KE"
              international
              value={value}
              error={undefined}
              onChange={onChange}
              withCountryCallingCode
              placeholder={placeholder}
              className={classes.phoneInput}
            />
          )}
        />
        {error && errorMessage && (
          <Typography
            color="error"
            variant="subtitle2"
            className={classes.errorText}
          >
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
}
