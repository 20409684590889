export enum ApiPaths {
  HOME = '/',
  LOGIN = '/auth/login',
  REGISTER = '/auth/register',
  USER = '/user',
  PROPERTIES = '/properties',
  UNITS = '/units',
  DOC = '/api-docs',
  MPESA = '/payments/mpesa',
  CARD = '/payments/card',
  PAYMENT_CALLBACK_URL = '/payments/call_back_url',
}
