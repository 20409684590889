import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callGet,
  callPut,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  ParamsRole,
  Properties,
  PropertyForm,
  ApiProperties,
  PropertyUpdateForm,
  PropertiesResponse,
} from '@real-estate-portal/interfaces';

export const propertiesGet = createAsyncThunk(
  'properties/all',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { params, role } = param;
      const { data } = await callGet<ApiProperties>(
        getHttpEndpoint(role, `${params}`).propertiesGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const propertyGet = createAsyncThunk(
  'properties/property',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callGet<Properties>(
        getHttpEndpoint(role, `/${id}`).propertyGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const propertyPost = createAsyncThunk(
  'properties/create',
  async (params: PropertyForm, thunkAPI) => {
    try {
      const { data } = await callPost<PropertiesResponse, PropertyForm>(
        getHttpEndpoint(params.role).propertyPost ?? '',
        params
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const propertyPut = createAsyncThunk(
  'properties/update',
  async (property: PropertyUpdateForm, thunkAPI) => {
    try {
      const { data } = await callPut<PropertiesResponse, PropertyForm>(
        getHttpEndpoint(property.role, `/${property.uuid}`).propertyPut ?? '',
        property.params
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const propertyDelete = createAsyncThunk(
  'properties/delete',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callDelete<{
        message: string;
        data: Properties[];
      }>(getHttpEndpoint(role, `/${id}`).propertyDelete ?? '');

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
