import { primaryColors } from '@real-estate-portal/constants';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  modalWidth?: number;
}

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    paper: {
      margin: '10% auto',
      position: 'relative',
      padding: theme.spacing(2, 4, 3),
      backgroundColor: primaryColors.white,
      width: ({ modalWidth }) => modalWidth ?? 500,
    },
    icon: {
      position: 'absolute',
      right: 10,
      cursor: 'pointer',
      width: 15,
      height: 15,
    },
    text: {
      fontSize: '1.2rem',
      marginTop: 15,
      width: '60%',
      margin: '2% auto 4%',
      textAlign: 'center',
    },
    ctaContainer: {
      width: '60%',
      margin: '2% auto',
      display: 'flex',
      justifyContent: 'space-between',
    },
    deleteBtn: {
      marginLeft: 15,
    },
    btnProgress: {
      color: primaryColors.lightGrey,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export default useStyles;
