import { createStyles, makeStyles } from '@material-ui/core';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() =>
  createStyles({
    thumbnailCard: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      borderBottom: `thin solid ${secondaryColors.lightGrey}`,
      cursor: 'pointer',
      backgroundColor: primaryColors.white,
      borderRight: `thin solid ${secondaryColors.lightGrey}`,

      '&:last-child': {
        borderRight: 'none',
      },

      '&:hover': {
        background:
          'repeating-linear-gradient(45deg, transparent, transparent 10px, #ccc 10px, #ccc 20px)',
      },
    },
    icon: {
      width: 50,
      height: 50,
      marginLeft: 20,
    },
    IconText: {
      marginLeft: 20,
    },
  })
);

export default useStyles;
