import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProcessingStatus } from '@real-estate-portal/enums';
import { PaymentForm } from '@real-estate-portal/interfaces';

import { paymentsPut } from './actions';
import { resetStatus, selectPutStatus } from './slice';

interface UsePayments {
  paymentsPutStatus: ProcessingStatus;

  resetStateStatues: () => void;
  updatePayment: (params: PaymentForm) => void;
}

export function usePayments(): UsePayments {
  const dispatch = useDispatch();

  const paymentsPutStatus = useSelector(selectPutStatus);

  const updatePayment = useCallback(
    (params: PaymentForm) => {
      dispatch(paymentsPut(params));
    },
    [dispatch]
  );

  const resetStateStatues = useCallback(() => {
    dispatch(resetStatus());
  }, [dispatch]);

  return {
    updatePayment,
    paymentsPutStatus,
    resetStateStatues,
  };
}
