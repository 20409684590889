import React from 'react';
import cn from 'classnames';
import { Button, CircularProgress } from '@material-ui/core';

import useStyles from './styles';

type ThemeColor = 'inherit' | 'primary' | 'secondary' | 'default' | undefined;

interface Props {
  id: string;
  text: string;

  theme?: ThemeColor;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  'data-test'?: string;
  type?: 'submit' | 'button';
  variant?: 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';

  onClick?: () => void;
}

export function CustomButton(props: Props): JSX.Element {
  const {
    id,
    text,
    className,
    size = 'medium',
    type = 'button',
    isLoading = false,
    theme = 'default',
    isDisabled = false,
    variant = 'contained',
    onClick: handleClick,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Button
      disableElevation
      id={id}
      size={size}
      type={type}
      color={theme}
      variant={variant}
      disabled={isDisabled}
      data-testid={rest['data-test']}
      className={cn(classes.root, className)}
      onClick={handleClick}
    >
      {text}
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
}
