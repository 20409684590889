import { configureStore } from '@reduxjs/toolkit';
import { logsReducer } from '@real-estate-portal/data-access/logs';
import { unitsReducer } from '@real-estate-portal/data-access/units';
import { billsReducer } from '@real-estate-portal/data-access/bills';
import { popupReducer } from '@real-estate-portal/data-access/popups';
import { reportsReducer } from '@real-estate-portal/data-access/reports';
import { tenantsReducer } from '@real-estate-portal/data-access/tenants';
import { accountReducer } from '@real-estate-portal/data-access/account';
import { paymentsReducer } from '@real-estate-portal/data-access/payments';
import { settingsReducer } from '@real-estate-portal/data-access/settings';
import { billingsReducer } from '@real-estate-portal/data-access/billings';
import { documentsReducer } from '@real-estate-portal/data-access/documents';
import { propertiesReducer } from '@real-estate-portal/data-access/properties';
import { authDataReducer } from '@real-estate-portal/data-access/authentication';
import { transactionsReducer } from '@real-estate-portal/data-access/transactions';

import { environment } from '../environments/environment';

export const store = configureStore({
  reducer: {
    logs: logsReducer,
    popup: popupReducer,
    units: unitsReducer,
    bills: billsReducer,
    auth: authDataReducer,
    reports: reportsReducer,
    tenants: tenantsReducer,
    account: accountReducer,
    payments: paymentsReducer,
    settings: settingsReducer,
    billings: billingsReducer,
    property: propertiesReducer,
    documents: documentsReducer,
    transaction: transactionsReducer,
  },
  devTools: !environment.production,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
