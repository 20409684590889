import { createStyles, makeStyles } from '@material-ui/core';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: 20,
      borderBottom: `thin solid ${secondaryColors.lightGrey}`,
      display: 'flex',
      width: '100%',

      '& > div': {
        padding: 15,
      },
    },
    propertyCardLeft: {
      flexBasis: '70%',
    },
    propertyCardRight: {
      flexBasis: '30%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    estateName: {
      color: primaryColors.lightGrey,
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    estateOwner: {
      color: primaryColors.lightGrey,
      fontSize: '0.9375rem',
    },
    estateLocation: {
      marginTop: 15,
      color: primaryColors.lightGrey,
      fontSize: '0.9375rem',
    },
    link: {
      color: primaryColors.blue,
      textDecoration: 'underline',
      textAlign: 'right',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
