import { makeStyles } from '@material-ui/core/styles';
import { primaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginTop: 15,
    display: 'block',
    fontSize: '0.8125rem',
  },
  selectFld: {
    width: 'auto',
  },
}));

export const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: 'transparent',
    background: 'rgba(255, 255, 255, 0.2)',
    boxShadow: state.isFocused ? null : null,
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,

    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? primaryColors.white : primaryColors.blue,
  }),
  input: (provided: any) => ({
    ...provided,
    color: primaryColors.white,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: primaryColors.white,
  }),
};

export const selectStylesB = {
  control: (base: any, state: any) => ({
    ...base,
    height: '43px',
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: state.isFocused ? null : null,
    borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
  }),
};

export default useStyles;
