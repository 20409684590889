import React from 'react';
import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { primaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 13,
    cursor: 'pointer',
    color: primaryColors.blue,

    '&:hover': {
      opacity: 0.8,
    },
  },
  remove: {
    color: primaryColors.red,
  },
});

interface Props {
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
}

export function CallToActions(props: Props): JSX.Element {
  const {
    onDelete: handleDelete,
    onEdit: handleEdit,
    onView: handleView,
  } = props;
  const { root, text, remove } = useStyles();

  return (
    <div className={root}>
      {handleEdit && (
        <Typography onClick={handleEdit} className={text} variant="subtitle2">
          Edit
        </Typography>
      )}
      {handleView && (
        <Typography onClick={handleView} className={text} variant="subtitle2">
          View
        </Typography>
      )}
      {handleDelete && (
        <Typography
          onClick={handleDelete}
          className={cn(text, remove)}
          variant="subtitle2"
        >
          Delete
        </Typography>
      )}
    </div>
  );
}
