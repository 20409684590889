import cn from 'classnames';
import Select from 'react-select';
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import { OptionType } from '@real-estate-portal/interfaces';

import useStyles, { selectStyles, selectStylesB } from './styles';

interface Props {
  name: string;
  value: OptionType;
  options: OptionType[];

  label?: string;
  error?: boolean;
  htmlFor?: string;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  isTransparent?: boolean;
  onChange?: (data: OptionType) => void;
}

export function FormSelectField(props: Props): JSX.Element {
  const {
    name,
    value,
    label,
    error,
    options,
    htmlFor,
    className,
    errorMessage,
    isMulti = false,
    disabled = false,
    isTransparent = false,
    onChange: handleChange,
  } = props;
  const classes = useStyles();
  const [option, setOption] = useState<OptionType>();

  const handleSelectChange = (selected: OptionType | unknown): void => {
    if (handleChange) {
      setOption(selected as OptionType);
      handleChange(selected as OptionType);
    }
  };

  return (
    <>
      {label && (
        <label htmlFor={htmlFor} className={classes.formLabel}>
          {label}
        </label>
      )}
      <Select
        name={name}
        isMulti={isMulti}
        options={options}
        isClearable={false}
        isDisabled={disabled}
        value={option ?? value}
        classNamePrefix="select"
        className={cn(classes.selectFld, className)}
        styles={isTransparent ? selectStyles : selectStylesB}
        onChange={handleSelectChange}
      />
      {error && errorMessage && (
        <Typography variant="subtitle2" color="error">
          {errorMessage}
        </Typography>
      )}
    </>
  );
}

export default FormSelectField;
