export enum RealEstatePaths {
  BASE_URL = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  RESET_PASSWORD = '/reset-password/:role',

  SEARCH = '/search',
  TENANTS = '/tenants',
  TENANT = '/tenants/:id',

  CHARGES = '/charges',
  SERVICES = '/services',

  PAYMENTS = '/payments',
  TRANSACTIONS = '/transactions',

  LOGS = '/logs',
  SETTINGS = '/settings',
  DASHBOARD = '/dashboard',
  DASHBOARD_TENANT = '/welcome',
  NOTIFICATIONS = '/notifications',

  PROPERTIES = '/properties',
  PROPERTY = '/properties/:id',
  PROPERTY_UNIT = '/properties/unit/:id',
  PROPERTY_NEW_ONBOARDING = '/property-new-onboarding',
}
