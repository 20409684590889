import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { secondaryColors } from '@real-estate-portal/constants';

interface Props {
  isFilterable: boolean;
}

type GetStyle = (prop: { isFilterable: boolean }) => string;

function getStyle(leftValue: string, rightValue: string): GetStyle {
  return ({ isFilterable }) => (isFilterable ? leftValue : rightValue);
}

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    root: {
      marginBottom: 15,
    },
    title: {
      width: '100%',
      marginTop: '3%',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: secondaryColors.darkGrey,
    },
    btnContainer: {
      width: '100%',
      display: 'flex',
      marginTop: getStyle('15px', '0px'),
      justifyContent: getStyle('space-between', 'flex-end'),
    },
    filter: {
      //
    },
  })
);

export default useStyles;
