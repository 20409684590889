import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Cycle,
  Billing,
  ParamsRole,
  BillingsForm,
  IWaterReadings,
  ApiWaterReadings,
  IWaterReadingsForm,
  IWaterReadingsParams,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  billingPut,
  billingCyclesGet,
  waterReadingsGet,
  waterReadingsPost,
  billingsByUnitGet,
  billingsByUserGet,
  waterReadingsAllGet,
} from './actions';
import {
  clearState,
  clearStatus,
  selectBillings,
  selectBillingCycles,
  selectWaterReadings,
  selectWaterReadingsAll,
  selectBillingPutStatus,
  selectBillingsGetStatus,
  selectBillingCycleGetStatus,
  selectWaterReadingsGetStatus,
  selectWaterReadingsAllStatus,
  selectWaterReadingsPostStatus,
} from './slice';

interface UseBillings {
  billingCycles: Cycle[];
  billings: Billing | undefined;

  billingPutStatus: ProcessingStatus;
  billingsGetStatus: ProcessingStatus;
  billingsCycleGetStatus: ProcessingStatus;
  waterReadingsGetStatus: ProcessingStatus;
  waterReadingsPostStatus: ProcessingStatus;
  waterReadings: IWaterReadings | undefined;
  waterReadingsAllGetStatus: ProcessingStatus;
  waterReadingsAll: ApiWaterReadings | undefined;

  clearBillingState: () => void;
  clearBillingStatus: () => void;
  fetchBillingCycles: (role: Roles) => void;
  fetchBillings: (param: ParamsRole) => void;
  updateBilling: (param: BillingsForm) => void;
  fetchUserBillings: (param: ParamsRole) => void;
  fetchWaterReadingsAll: (params: ParamsRole) => void;
  createWaterReadings: (param: IWaterReadingsForm) => void;
  fetchWaterReadings: (params: IWaterReadingsParams) => void;
}

export function useBillings(): UseBillings {
  const dispatch = useDispatch();

  const billings = useSelector(selectBillings);
  const waterReadings = useSelector(selectWaterReadings);
  const billingCycles = useSelector(selectBillingCycles);
  const billingPutStatus = useSelector(selectBillingPutStatus);

  const billingsGetStatus = useSelector(selectBillingsGetStatus);
  const billingsCycleGetStatus = useSelector(selectBillingCycleGetStatus);
  const waterReadingsGetStatus = useSelector(selectWaterReadingsGetStatus);
  const waterReadingsPostStatus = useSelector(selectWaterReadingsPostStatus);

  const waterReadingsAll = useSelector(selectWaterReadingsAll);
  const waterReadingsAllGetStatus = useSelector(selectWaterReadingsAllStatus);

  const fetchBillingCycles = useCallback(
    (role: Roles) => {
      dispatch(billingCyclesGet(role));
    },
    [dispatch]
  );

  const fetchWaterReadings = useCallback(
    (params: IWaterReadingsParams) => {
      dispatch(waterReadingsGet(params));
    },
    [dispatch]
  );

  const fetchWaterReadingsAll = useCallback(
    (params: ParamsRole) => {
      dispatch(waterReadingsAllGet(params));
    },
    [dispatch]
  );

  const fetchBillings = useCallback(
    (param: ParamsRole) => {
      dispatch(billingsByUnitGet(param));
    },
    [dispatch]
  );

  const updateBilling = useCallback(
    (param: BillingsForm) => {
      dispatch(billingPut(param));
    },
    [dispatch]
  );

  const createWaterReadings = useCallback(
    (param: IWaterReadingsForm) => {
      dispatch(waterReadingsPost(param));
    },
    [dispatch]
  );

  const clearBillingState = useCallback(() => {
    dispatch(clearState());
  }, [dispatch]);

  const clearBillingStatus = useCallback(() => {
    dispatch(clearStatus());
  }, [dispatch]);

  const fetchUserBillings = useCallback(
    (param: ParamsRole) => {
      dispatch(billingsByUserGet(param));
    },
    [dispatch]
  );

  return {
    billings,
    billingCycles,
    fetchBillings,
    updateBilling,
    waterReadings,
    waterReadingsAll,
    billingPutStatus,
    clearBillingState,
    fetchUserBillings,
    billingsGetStatus,
    clearBillingStatus,
    fetchBillingCycles,
    fetchWaterReadings,
    createWaterReadings,
    fetchWaterReadingsAll,
    billingsCycleGetStatus,
    waterReadingsGetStatus,
    waterReadingsPostStatus,
    waterReadingsAllGetStatus,
  };
}
