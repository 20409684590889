import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  User,
  ApiUser,
  ParamsRole,
  ApiCompany,
  CompanyForm,
  AccountForm,
  ApiTempAdmins,
  PasswordSettings,
  AccountStateForm,
} from '@real-estate-portal/interfaces';
import { Roles, ProcessingStatus } from '@real-estate-portal/enums';

import {
  userGet,
  userPut,
  adminsGet,
  logoutPost,
  passwordPut,
  companyPost,
  companiesGet,
  tempAdminsGet,
  changeAdminState,
  adminDelete,
} from './actions';
import {
  selectRole,
  selectUser,
  selectAdmins,
  selectCompanies,
  selectTempAdmins,
  resetStatusStates,
  selectLogoutStatus,
  selectUserGetStatus,
  selectUserPutStatus,
  selectAdminsGetStatus,
  selectCompanyPostStatus,
  selectPasswordPutStatus,
  selectCompaniesGetStatus,
  selectTempAdminsGetStatus,
  selectChangeAdminStateStatus,
  selectDeleteAdminStatus,
} from './slice';

interface UseAccount {
  role: Roles;
  user: User | undefined;
  admins: ApiUser | undefined;
  logoutStatus: ProcessingStatus;
  userGetStatus: ProcessingStatus;
  userPutStatus: ProcessingStatus;
  companies: ApiCompany | undefined;
  adminsGetStatus: ProcessingStatus;
  companyPostStatus: ProcessingStatus;
  passwordPutStatus: ProcessingStatus;
  deleteAdminStatus: ProcessingStatus;
  companiesGetStatus: ProcessingStatus;
  tempAdminGetStatus: ProcessingStatus;
  tempAdmins: ApiTempAdmins | undefined;
  changeAdminStateStatus: ProcessingStatus;

  resetStatus: () => void;
  fetchUser: (role: Roles) => void;
  logoutUser: (role: Roles) => void;
  updateUser: (params: AccountForm) => void;
  fetchAdmins: (params: ParamsRole) => void;
  deleteAdmin: (params: ParamsRole) => void;
  fetchCompanies: (params: ParamsRole) => void;
  createCompany: (params: CompanyForm) => void;
  fetchTempAdmins: (params: ParamsRole) => void;
  updatePassword: (params: PasswordSettings) => void;
  updateAdminState: (params: AccountStateForm) => void;
}

export function useAccount(): UseAccount {
  const dispatch = useDispatch();

  const role = useSelector(selectRole);
  const user = useSelector(selectUser);
  const admins = useSelector(selectAdmins);
  const companies = useSelector(selectCompanies);
  const tempAdmins = useSelector(selectTempAdmins);
  const logoutStatus = useSelector(selectLogoutStatus);
  const userGetStatus = useSelector(selectUserGetStatus);
  const userPutStatus = useSelector(selectUserPutStatus);
  const adminsGetStatus = useSelector(selectAdminsGetStatus);
  const deleteAdminStatus = useSelector(selectDeleteAdminStatus);
  const companyPostStatus = useSelector(selectCompanyPostStatus);
  const passwordPutStatus = useSelector(selectPasswordPutStatus);
  const companiesGetStatus = useSelector(selectCompaniesGetStatus);
  const tempAdminGetStatus = useSelector(selectTempAdminsGetStatus);
  const changeAdminStateStatus = useSelector(selectChangeAdminStateStatus);

  const fetchUser = useCallback(
    (role: Roles) => {
      dispatch(userGet(role));
    },
    [dispatch]
  );

  const fetchCompanies = useCallback(
    (params: ParamsRole) => {
      dispatch(companiesGet(params));
    },
    [dispatch]
  );

  const deleteAdmin = useCallback(
    (params: ParamsRole) => {
      dispatch(adminDelete(params));
    },
    [dispatch]
  );

  const fetchTempAdmins = useCallback(
    (params: ParamsRole) => {
      dispatch(tempAdminsGet(params));
    },
    [dispatch]
  );

  const createCompany = useCallback(
    (params: CompanyForm) => {
      dispatch(companyPost(params));
    },
    [dispatch]
  );

  const fetchAdmins = useCallback(
    (params: ParamsRole) => {
      dispatch(adminsGet(params));
    },
    [dispatch]
  );

  const updateAdminState = useCallback(
    (params: AccountStateForm) => {
      dispatch(changeAdminState(params));
    },
    [dispatch]
  );

  const resetStatus = useCallback(() => {
    dispatch(resetStatusStates());
  }, [dispatch]);

  const updateUser = useCallback(
    (params: AccountForm) => {
      dispatch(userPut(params));
    },
    [dispatch]
  );

  const updatePassword = useCallback(
    (params: PasswordSettings) => {
      dispatch(passwordPut(params));
    },
    [dispatch]
  );

  const logoutUser = useCallback(
    (role: Roles) => {
      dispatch(logoutPost(role));
    },
    [dispatch]
  );

  return {
    role,
    user,
    admins,
    companies,
    fetchUser,
    updateUser,
    logoutUser,
    tempAdmins,
    deleteAdmin,
    fetchAdmins,
    resetStatus,
    logoutStatus,
    userGetStatus,
    userPutStatus,
    createCompany,
    fetchCompanies,
    updatePassword,
    adminsGetStatus,
    fetchTempAdmins,
    updateAdminState,
    deleteAdminStatus,
    passwordPutStatus,
    companyPostStatus,
    companiesGetStatus,
    tempAdminGetStatus,
    changeAdminStateStatus,
  };
}
