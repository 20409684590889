import React, { Suspense } from 'react';
import { Spinner } from '../spinner/Spinner';

export function Lazy(Component: React.ComponentType): React.FunctionComponent {
  return (): JSX.Element => (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );
}
