import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ParamsRole,
  AdminReports,
  TenantReport,
  SearchApiResponse,
  SearchForm,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  selectAdminReports,
  selectTenantReports,
  selectAdminGetStatus,
  selectTenantGetStatus,
  selectSearchGetStatus,
  selectSearchResults,
} from './slice';
import { reportGet, reportsGet, searchGet } from './actions';

interface UseReports {
  adminReports: AdminReports;
  tenantReports: TenantReport;
  searchResults: SearchApiResponse;

  searchGetStatus: ProcessingStatus;
  reportTenantGetStatus: ProcessingStatus;
  reportsAdminGetStatus: ProcessingStatus;

  fetchAdminReports: (role: Roles) => void;
  fetchSearchResults: (param: SearchForm) => void;
  fetchTenantReports: (param: ParamsRole) => void;
}

export function useReports(): UseReports {
  const dispatch = useDispatch();

  const adminReports = useSelector(selectAdminReports);
  const searchResults = useSelector(selectSearchResults);
  const tenantReports = useSelector(selectTenantReports);
  const searchGetStatus = useSelector(selectSearchGetStatus);
  const reportsAdminGetStatus = useSelector(selectAdminGetStatus);
  const reportTenantGetStatus = useSelector(selectTenantGetStatus);

  const fetchTenantReports = useCallback(
    (param: ParamsRole) => {
      dispatch(reportGet(param));
    },
    [dispatch]
  );

  const fetchSearchResults = useCallback(
    (param: SearchForm) => {
      dispatch(searchGet(param));
    },
    [dispatch]
  );

  const fetchAdminReports = useCallback(
    (role: Roles) => {
      dispatch(reportsGet(role));
    },
    [dispatch]
  );

  return {
    adminReports,
    searchResults,
    tenantReports,
    searchGetStatus,
    fetchAdminReports,
    fetchSearchResults,
    fetchTenantReports,
    reportsAdminGetStatus,
    reportTenantGetStatus,
  };
}
