import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';

import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

export const StyledTableCell = withStyles((theme: Theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme: Theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export const useTableStyles = makeStyles({
  subText: {
    fontSize: '0.7rem',
  },
  dateWrapper: {
    display: 'box',
    fontSize: '0.6rem',
  },
  status: {
    color: primaryColors.blue,
  },
  downloadLink: {
    float: 'right',
    fontSize: '0.8rem',
    color: primaryColors.blue,
  },
  transBtn: {
    marginTop: 15,
  },
  table: {
    border: `1px solid ${secondaryColors.lightGrey}`,
  },
  noTick: {
    color: primaryColors.red,
  },
  yesTick: {
    color: primaryColors.blue,
  },
  btn: {
    marginTop: 15,
    marginBottom: 5,
  },
  delBtnOpacity: {
    opacity: 0.2,
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.9rem',
    color: primaryColors.blue,
    textDecoration: 'underline',
  },
  statsTable: {
    marginBottom: 30,
  },
  cta: {
    fontSize: 13,

    '&:last-child': {
      marginLeft: '3px',
      color: primaryColors.red,
    },
  },
  ctaWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    cursor: 'pointer',
    color: primaryColors.blue,
    textDecoration: 'underline',
  },
  printWrap: {
    padding: 5,
    width: '100%',
    borderTop: `1px dashed ${secondaryColors.lightGrey}`,
    borderLeft: `1px dashed ${secondaryColors.lightGrey}`,
  },
  printTitle: {
    fontSize: '0.75rem',
    color: secondaryColors.darkGrey,
  },
  printValue: {
    fontSize: '1rem',
    color: primaryColors.blue,
  },
  printDetailsWrapper: {
    marginBottom: 10,
  },
  btnDownload: {
    marginBottom: 20,
  },
  activeTenant: {
    color: primaryColors.blue,
  },
  cronSubText: {
    fontSize: '0.7rem',
  },
  tableTitle: {
    marginTop: 15,
    marginBottom: 15,
    color: primaryColors.blue,
    textDecoration: 'underline',
  },
  downloadContainer: {
    marginTop: 10,
    width: '100%',
    marginBottom: 5,
    display: 'block',
  },
});
