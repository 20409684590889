import React from 'react';
import { Uploader, UploadWidgetResult } from 'uploader';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { UploadDropzone, UploadButton } from 'react-uploader';
import { Grid, GridSize, Typography } from '@material-ui/core';

import { formatTime } from '@real-estate-portal/utils';
import { Documents } from '@real-estate-portal/interfaces';

import useStyles from './styles';

const options = { multi: false };
const uploader = Uploader({
  apiKey: process.env.NX_API_FILE_UPLOAD_KEY ?? '',
});

interface Props {
  files: Documents[];

  mdSize?: GridSize;
  isUploadBtn?: boolean;
  hasUploadBtn?: boolean;
  isDocRemovable?: boolean;
  onRemoveFile?: (file: Documents) => void;
  onUpdateFiles?: (files: UploadWidgetResult[]) => void;
}

export function DocumentZone(props: Props): JSX.Element {
  const {
    files,
    mdSize,
    isUploadBtn = false,
    hasUploadBtn = false,
    isDocRemovable = false,
    onUpdateFiles: handleUpdateFiles,
  } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {hasUploadBtn && (
        <Grid item md={mdSize ?? 6}>
          <div className={classes.uploadWrapper}>
            {isUploadBtn && (
              <UploadButton
                options={options}
                uploader={uploader}
                onComplete={handleUpdateFiles}
              >
                {({ onClick }) => (
                  <button className={classes.uploadFileBtn} onClick={onClick}>
                    Upload a file...
                  </button>
                )}
              </UploadButton>
            )}

            {!isUploadBtn && (
              <UploadDropzone
                width="100%"
                height="275px"
                options={options}
                uploader={uploader}
                onUpdate={handleUpdateFiles}
              />
            )}
          </div>
        </Grid>
      )}

      <Grid item md={mdSize ?? 6}>
        {files.length > 0 &&
          files.map((file, index) => (
            <div className={classes.fileWrapper} key={index}>
              <AttachFileIcon />
              <span>
                <Typography className={classes.fileName}>
                  {file.origin_name}{' '}
                  <span className={classes.time}>
                    {formatTime(file.created_at)}
                  </span>
                  <span className={classes.time}>
                    {Number(file.file_size) / 1000} kb
                  </span>
                </Typography>

                <div className={classes.fileCtaWrapper}>
                  <a
                    download
                    target="_blank"
                    rel="noreferrer"
                    href={file.file_url}
                    className={classes.fileCta}
                  >
                    DOWNLOAD
                  </a>
                  {isDocRemovable && (
                    <Typography variant="subtitle2" className={classes.fileCta}>
                      REMOVE
                    </Typography>
                  )}
                </div>
              </span>
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

export default DocumentZone;
