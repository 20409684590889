import {
  User,
  Tenant,
  Billing,
  SumOption,
  OptionType,
  RentalCycle,
  Transaction,
  BillingCycle,
} from '@real-estate-portal/interfaces';

export function getTransSum(
  accumulative: number,
  transaction: Transaction
): number {
  return accumulative + Number(transaction.amount);
}

export function getBillChargeSum(
  accumulative: number,
  billing: BillingCycle
): number {
  return accumulative + Number(billing.charged_amount);
}

export function getBillPaidSum(
  accumulative: number,
  billing: BillingCycle
): number {
  return accumulative + Number(billing.paid_amount);
}

export function getBillPardonSum(
  accumulative: number,
  billing: BillingCycle
): number {
  return accumulative + Number(billing.pardon);
}

export function getRentalPardonSum(
  accumulative: number,
  rental: RentalCycle
): number {
  return accumulative + Number(rental.pardon);
}

export function getRentalPaidSum(
  accumulative: number,
  rental: RentalCycle
): number {
  return accumulative + Number(rental.paid_amount);
}

export function getRentalChargeSum(
  accumulative: number,
  rental: RentalCycle
): number {
  return accumulative + Number(rental.charged_amount);
}

export const getCompanyInfo = (): OptionType[] => {
  return [
    { label: 'Company Inc.', value: 'Gelin Management' },
    {
      label: 'Email Address.',
      value: 'info@gelinmanagement.com',
    },
    { label: 'Phone Number.', value: '0718768624/0701176695' },
  ];
};

type T = User | undefined;
export const getTenantInfo = (singleTenant: T): OptionType[] => {
  return [
    {
      label: 'Full Names.',
      value: singleTenant ? singleTenant.user.accountHolder : 'xxxx xxxx',
    },
    {
      label: 'Email Address.',
      value: singleTenant ? singleTenant.user.email : 'xxxx xxxx',
    },
    {
      label: 'Phone Number.',
      value: singleTenant ? singleTenant.user.phoneNumber : 'xxxx xxxx',
    },
  ];
};

export const getSumBillings = (data: Billing): SumOption[] => {
  const paid =
    data.billings.reduce(getBillPaidSum, 0) +
    data.rental.reduce(getRentalPaidSum, 0);
  const pardon =
    data.billings.reduce(getBillPardonSum, 0) +
    data.rental.reduce(getRentalPardonSum, 0);
  const charged =
    data.billings.reduce(getBillChargeSum, 0) +
    data.rental.reduce(getRentalChargeSum, 0);

  return [
    {
      label: 'Paid',
      value: paid,
    },
    {
      label: 'Pardon',
      value: pardon,
    },
    {
      label: 'Charged',
      value: charged,
    },
  ];
};

type Trans = Transaction[] | undefined;
export const getSumTransactions = (transactions: Trans): SumOption[] => {
  if (transactions) {
    return [{ label: 'Total', value: transactions.reduce(getTransSum, 0) }];
  }

  return [{ label: 'Total', value: 0 }];
};
