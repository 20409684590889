import {
  EXPIRATION_DATE,
  TOKEN_STORAGE_NAME,
} from '@real-estate-portal/constants';

import { getPaths } from '..';
import { deleteStoredData } from './local-storage';

export function logout(): void {
  deleteStoredData(EXPIRATION_DATE);
  deleteStoredData(TOKEN_STORAGE_NAME);

  window.location.href = `${getPaths().baseUrl}`;
}
