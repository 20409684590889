import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  callGet,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  Documents,
  RespStatus,
  ParamsRole,
  DocumentsForm,
} from '@real-estate-portal/interfaces';

export const documentPost = createAsyncThunk(
  'documents/create',
  async (payload: DocumentsForm, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, DocumentsForm>(
        getHttpEndpoint(payload.role).docPost ?? '',
        payload
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const documentsGet = createAsyncThunk(
  'documents/fetch',
  async (props: ParamsRole, thunkAPI) => {
    try {
      const { id, params, role } = props;
      const { data } = await callGet<Documents[]>(
        getHttpEndpoint(role, `/${id}${params}`).docFetch ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const documentDelete = createAsyncThunk(
  'documents/delete',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callDelete<RespStatus>(
        getHttpEndpoint(role, `/${id}`).docDelete ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
