import React from 'react';
import cn from 'classnames';
import Select from 'react-select';
import { Control, Controller } from 'react-hook-form';
import { Grid, GridSize, Typography, FormControl } from '@material-ui/core';

import { OptionType } from '@real-estate-portal/interfaces';
import useStyles, { selectStyles, selectStylesB } from './styles';

interface Props {
  name: string;
  control: Control;
  options: OptionType[];

  label?: string;
  error?: boolean;
  size?: GridSize;
  htmlFor?: string;
  isMulti?: boolean;
  className?: string;
  isDisabled?: boolean;
  'data-test'?: string;
  errorMessage?: string;
  isTransparent?: boolean;
  defaultValue?: OptionType;

  onChange?: (e: OptionType) => void;
}

export function FormDropdownField(props: Props): JSX.Element {
  const {
    size,
    name,
    label,
    error,
    control,
    options,
    htmlFor,
    className,
    defaultValue,
    errorMessage,
    isMulti = false,
    isDisabled = false,
    isTransparent = false,
    onChange: handleChange,
  } = props;

  const classes = useStyles();

  return (
    <Grid container item md={size} className={className}>
      <FormControl fullWidth>
        {label && (
          <label htmlFor={htmlFor} className={classes.formLabel}>
            {label}
          </label>
        )}
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ name, value, onChange, onBlur }): JSX.Element => (
            <Select
              name={name}
              value={value}
              isMulti={isMulti}
              options={options}
              isClearable={false}
              isDisabled={isDisabled}
              classNamePrefix="select"
              className={cn(classes.selectFld, className)}
              styles={isTransparent ? selectStyles : selectStylesB}
              onChange={(e) => {
                onChange(e);

                if (handleChange) {
                  handleChange(e);
                }
              }}
              onBlur={onBlur}
            />
          )}
        />
        {error && errorMessage && (
          <Typography variant="subtitle2" color="error">
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
}
