import { createSlice } from '@reduxjs/toolkit';
import { ProcessingStatus } from '@real-estate-portal/enums';
import { ApiCronLog, ApiSystemLog } from '@real-estate-portal/interfaces';

import { billingsCronLogsGet, systemLogsGet } from './actions';

interface LogsState {
  systemLogs: ApiSystemLog | undefined;
  billingCronLogs: ApiCronLog | undefined;

  systemLogsGetStatus: ProcessingStatus;
  billingsCronLogsGetStatus: ProcessingStatus;
}

export const initialState: LogsState = {
  systemLogs: undefined,
  billingCronLogs: undefined,

  systemLogsGetStatus: ProcessingStatus.IDLE,
  billingsCronLogsGetStatus: ProcessingStatus.IDLE,
};

interface LogsSlice {
  logs: LogsState;
}

export const logsSlice = createSlice({
  initialState,
  reducers: {},
  name: 'logs',
  extraReducers: (builder) => {
    builder.addCase(systemLogsGet.pending, (state) => {
      state.systemLogsGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(systemLogsGet.fulfilled, (state, action) => {
      state.systemLogsGetStatus = ProcessingStatus.SUCCEEDED;
      state.systemLogs = action.payload;
    });

    builder.addCase(systemLogsGet.rejected, (state) => {
      state.systemLogsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(billingsCronLogsGet.pending, (state) => {
      state.billingsCronLogsGetStatus = ProcessingStatus.PENDING;
    });

    builder.addCase(billingsCronLogsGet.fulfilled, (state, action) => {
      state.billingsCronLogsGetStatus = ProcessingStatus.SUCCEEDED;
      state.billingCronLogs = action.payload;
    });

    builder.addCase(billingsCronLogsGet.rejected, (state) => {
      state.billingsCronLogsGetStatus = ProcessingStatus.FAILED;
    });
  },
});

export const logsReducer = logsSlice.reducer;

export const selectSystemLogsGetStatus = (state: LogsSlice): ProcessingStatus =>
  state.logs.systemLogsGetStatus;

export const selectBillingsCronLogsGetStatus = (
  state: LogsSlice
): ProcessingStatus => state.logs.billingsCronLogsGetStatus;

export const selectSystemLogs = (state: LogsSlice): ApiSystemLog | undefined =>
  state.logs.systemLogs;

export const selectBillingCronLogs = (
  state: LogsSlice
): ApiCronLog | undefined => state.logs.billingCronLogs;
