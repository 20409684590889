import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SearchForm,
  ParamsRole,
  AdminReports,
  TenantReport,
  SearchApiResponse,
} from '@real-estate-portal/interfaces';
import { Roles } from '@real-estate-portal/enums';
import { callGet, getHttpEndpoint } from '@real-estate-portal/utils';

export const reportsGet = createAsyncThunk(
  'reports/admin',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<AdminReports>(
        getHttpEndpoint(role).reportsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const reportGet = createAsyncThunk(
  'reports/tenant',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = param;
      const { data } = await callGet<TenantReport>(
        getHttpEndpoint(role, `/${id}`).reportGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const searchGet = createAsyncThunk(
  'reports/search',
  async (payload: SearchForm, thunkAPI) => {
    const param = `?type=${payload.type}&query=${payload.query}`;

    try {
      const { data } = await callGet<SearchApiResponse>(
        getHttpEndpoint(payload.role, param).searchGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
