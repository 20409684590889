import { createStyles, makeStyles } from '@material-ui/core';
import { primaryColors, secondaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() =>
  createStyles({
    thumbnailCard: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      border: `thin solid ${secondaryColors.lightGrey}`,
      cursor: 'pointer',
    },
    icon: {
      width: 64,
      height: 64,
      marginLeft: 20,
    },
    text: {
      marginLeft: 20,
      fontWeight: 'bold',
      color: secondaryColors.darkGrey,
    },
    profText: {
      fontSize: '0.8rem',
      fontWeight: 'normal',
    },
    contactText: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
      color: primaryColors.blue,
      textDecoration: 'underline',
      marginTop: 5,
      cursor: 'pointer',
    },
  })
);

export default useStyles;
