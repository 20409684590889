import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ParamsRole,
  ApiCronLog,
  ApiSystemLog,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  selectSystemLogs,
  selectBillingCronLogs,
  selectSystemLogsGetStatus,
  selectBillingsCronLogsGetStatus,
} from './slice';
import { systemLogsGet, billingsCronLogsGet } from './actions';

interface UseLogs {
  systemLogs: ApiSystemLog | undefined;
  billingCronLogs: ApiCronLog | undefined;

  systemLogsGetStatus: ProcessingStatus;
  billingsCronLogsGetStatus: ProcessingStatus;

  fetchSystemLogs: (params: ParamsRole) => void;
  fetchBillingsCronLogs: (params: ParamsRole) => void;
}

export function useLogs(): UseLogs {
  const dispatch = useDispatch();

  const systemLogs = useSelector(selectSystemLogs);
  const billingCronLogs = useSelector(selectBillingCronLogs);

  const systemLogsGetStatus = useSelector(selectSystemLogsGetStatus);
  const billingsCronLogsGetStatus = useSelector(
    selectBillingsCronLogsGetStatus
  );

  const fetchSystemLogs = useCallback(
    (param: ParamsRole) => {
      dispatch(systemLogsGet(param));
    },
    [dispatch]
  );

  const fetchBillingsCronLogs = useCallback(
    (param: ParamsRole) => {
      dispatch(billingsCronLogsGet(param));
    },
    [dispatch]
  );

  return {
    systemLogs,
    fetchSystemLogs,
    billingCronLogs,
    systemLogsGetStatus,
    fetchBillingsCronLogs,
    billingsCronLogsGetStatus,
  };
}
