import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import cn from 'classnames';

import useStyles from './styles';

interface Props {
  icon: string;
  url?: string;
  name: string;
  phoneNumber: string;
  professional: string;
}

export function Service(props: Props): JSX.Element {
  const { icon, name, phoneNumber, professional } = props;
  const [isContact, setIsContact] = useState(false);
  const classes = useStyles();

  const handleContactSwitch = (): void => {
    setIsContact((prevState) => !prevState);
  };

  return (
    <div className={classes.thumbnailCard}>
      <img className={classes.icon} src={icon} alt="report icon" />

      <div>
        <Typography className={classes.text} variant="subtitle2">
          {name}
        </Typography>
        <Typography
          className={cn(classes.text, classes.profText)}
          variant="subtitle2"
        >
          {professional}
        </Typography>
        <Typography
          className={cn(classes.text, classes.contactText)}
          onClick={handleContactSwitch}
          variant="subtitle2"
        >
          {isContact ? phoneNumber : 'show contact'}
        </Typography>
      </div>
    </div>
  );
}
