import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ParamsRole,
  ApiCronLog,
  ApiSystemLog,
} from '@real-estate-portal/interfaces';
import { callGet, getHttpEndpoint } from '@real-estate-portal/utils';

export const systemLogsGet = createAsyncThunk(
  'logs/system-logs',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { params, role } = param;
      const { data } = await callGet<ApiSystemLog>(
        getHttpEndpoint(role, `${params}`).systemLogsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);

export const billingsCronLogsGet = createAsyncThunk(
  'logs/cron-logs',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { params, role } = param;
      const { data } = await callGet<ApiCronLog>(
        getHttpEndpoint(role, `${params}`).cronLogsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e.message] });
    }
  }
);
