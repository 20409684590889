import React from 'react';
import cn from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { OptionType } from '@real-estate-portal/interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 20,
      display: 'flex',
    },
    formControl: {
      // margin: theme.spacing(3),
    },
  })
);

interface Props {
  options: OptionType[];
  onChange: (id: string) => void;

  className?: string;
}

export function FormGroupCheckbox(props: Props) {
  const classes = useStyles();
  const { options, className, onChange: handleSelect } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSelect(event.target.value);
  };

  return (
    <div className={cn(classes.root, className)}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {options &&
            options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    color="primary"
                    name={option.label}
                    value={option.value}
                    checked={option['checked'] as boolean}
                    onChange={handleChange}
                  />
                }
                label={option.label}
              />
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
