import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Settings,
  EnvConfig,
  ParamsRole,
  SettingForm,
  DefaultSettings,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  settingPut,
  settingGet,
  settingPost,
  settingEnvGet,
  settingEnvPut,
} from './actions';
import {
  selectSettings,
  selectEnvConfig,
  selectPutStatus,
  selectGetStatus,
  selectPostStatus,
  resetStateStatuses,
  selectEnvGetStatus,
  selectEnvPutStatus,
  selectDefaultSettings,
  setDefaultSettings,
} from './slice';

interface UseSettings {
  envSettings: EnvConfig;
  settings: Settings | undefined;
  defaultSettings: DefaultSettings;
  settingsPutStatus: ProcessingStatus;
  settingsGetStatus: ProcessingStatus;
  settingsPostStatus: ProcessingStatus;
  settingsEnvPutStatus: ProcessingStatus;
  settingsEnvGetStatus: ProcessingStatus;

  resetStatus: () => void;
  fetchEnvSettings: (role: Roles) => void;
  fetchSettings: (param: ParamsRole) => void;
  createSetting: (params: SettingForm) => void;
  updateSetting: (params: SettingForm) => void;
  updateEnvSetting: (params: EnvConfig) => void;
  updateDefaultSettings: (param: DefaultSettings) => void;
}

export function useSettings(): UseSettings {
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);
  const envSettings = useSelector(selectEnvConfig);
  const settingsPutStatus = useSelector(selectPutStatus);
  const settingsGetStatus = useSelector(selectGetStatus);
  const settingsPostStatus = useSelector(selectPostStatus);
  const settingsEnvPutStatus = useSelector(selectEnvPutStatus);
  const settingsEnvGetStatus = useSelector(selectEnvGetStatus);
  const defaultSettings = useSelector(selectDefaultSettings);

  const updateDefaultSettings = useCallback(
    (param: DefaultSettings) => {
      dispatch(setDefaultSettings(param));
    },
    [dispatch]
  );

  const fetchSettings = useCallback(
    (param: ParamsRole) => {
      dispatch(settingGet(param));
    },
    [dispatch]
  );

  const fetchEnvSettings = useCallback(
    (role: Roles) => {
      dispatch(settingEnvGet(role));
    },
    [dispatch]
  );

  const resetStatus = useCallback(() => {
    dispatch(resetStateStatuses());
  }, [dispatch]);

  const createSetting = useCallback(
    (params: SettingForm) => {
      dispatch(settingPost(params));
    },
    [dispatch]
  );

  const updateSetting = useCallback(
    (params: SettingForm) => {
      dispatch(settingPut(params));
    },
    [dispatch]
  );

  const updateEnvSetting = useCallback(
    (params: EnvConfig) => {
      dispatch(settingEnvPut(params));
    },
    [dispatch]
  );

  return {
    settings,
    envSettings,
    resetStatus,
    fetchSettings,
    updateSetting,
    createSetting,
    defaultSettings,
    updateEnvSetting,
    fetchEnvSettings,
    settingsGetStatus,
    settingsPutStatus,
    settingsPostStatus,
    settingsEnvGetStatus,
    settingsEnvPutStatus,
    updateDefaultSettings,
  };
}
