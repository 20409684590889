import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  Cycle,
  Billing,
  ParamsRole,
  BillingsForm,
  IWaterReadings,
  ApiWaterReadings,
  IWaterReadingsForm,
  IWaterReadingsParams,
} from '@real-estate-portal/interfaces';
import {
  callGet,
  callPut,
  callPost,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import { Roles } from '@real-estate-portal/enums';

export const billingsByUnitGet = createAsyncThunk(
  'billings/per-unit',
  async (params: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = params;

      const { data } = await callGet<Billing>(
        getHttpEndpoint(role, `/${id}`).billingByUnit ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const billingsByUserGet = createAsyncThunk(
  'billings/per-by-user',
  async (params: ParamsRole, thunkAPI) => {
    try {
      const { id, role } = params;
      const { data } = await callGet<Billing>(
        getHttpEndpoint(role, `/${id}`).billingByUser ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const billingCyclesGet = createAsyncThunk(
  'billings/cycles',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<Cycle[]>(
        getHttpEndpoint(role).cyclesGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const billingPut = createAsyncThunk(
  'billings/update-billing',
  async (payload: BillingsForm, thunkAPI) => {
    try {
      const { uuid, ...rest } = payload;
      const { data } = await callPut<{ status: string }, BillingsForm>(
        getHttpEndpoint(payload.role, `/${uuid}`).billingPut ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const waterReadingsGet = createAsyncThunk(
  'billings/water-readings',
  async (param: IWaterReadingsParams, thunkAPI) => {
    const params = `/${param.unitId}?cycle=${param.cycleId}`;

    try {
      const { data } = await callGet<IWaterReadings>(
        getHttpEndpoint(param.role, params).waterReadingGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const waterReadingsAllGet = createAsyncThunk(
  'billings/water-readings-by-property',
  async (param: ParamsRole, thunkAPI) => {
    const params = `/${param.id}${param.params}`;

    try {
      const { data } = await callGet<ApiWaterReadings>(
        getHttpEndpoint(param.role, params).waterReadingsByPropsGet ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const waterReadingsPost = createAsyncThunk(
  'billings/create-water-billing',
  async (payload: IWaterReadingsForm, thunkAPI) => {
    try {
      const { uuid, ...rest } = payload;
      const { data } = await callPost<{ status: string }, IWaterReadingsForm>(
        getHttpEndpoint(payload.role).waterReadingPost ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);
