import { AxiosRequestConfig } from 'axios';
import { Generic } from '@real-estate-portal/interfaces';

import { getUserToken } from './local-storage';

export const assignTokenToHeader = {
  fulfilled: (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getUserToken();
    const tenant = window.location.pathname.split('/')[1];

    if (config.headers) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers['X-Tenant'] = `${tenant}`;
    }

    if (token && config.headers) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  rejected: (error: Generic): Promise<void> => {
    return Promise.reject(error);
  },
};
