import { makeStyles } from '@material-ui/core/styles';
import { primaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles(() => ({
  root: {
    width: 120,
    fontSize: 12,
    borderRadius: 0,
    textTransform: 'none',
    color: primaryColors.white,

    '&:hover': {
      color: primaryColors.white,
    },
  },
  buttonProgress: {
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    position: 'absolute',
    color: primaryColors.lightGrey,
  },
}));

export default useStyles;
