import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { primaryColors } from '@real-estate-portal/constants';

const useStyles = makeStyles<Theme, { isOccupied: boolean }>(() =>
  createStyles({
    root: {
      border: `1px solid ${primaryColors.lightGrey}`,
      height: 40,
      padding: 5,
      marginRight: 10,
      marginBottom: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      flexBasis: '22%',
      borderRadius: 3,

      '&:nth-child(even)': {
        background: ({ isOccupied }) =>
          isOccupied
            ? 'repeating-linear-gradient(45deg, transparent, transparent 10px, #ccc 10px, #ccc 20px)'
            : '',
      },

      '&:hover': {
        opacity: 0.5,
      },
    },
    subText: {
      display: 'block',
      fontSize: '0.5rem',
    },
  })
);

export default useStyles;
