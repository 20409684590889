import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Tenant,
  ApiTenant,
  ParamsRole,
  RegisterForm,
  TenantPayload,
  ApiTenantToUnit,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  tenantPut,
  tenantGet,
  tenantsGet,
  tenantPost,
  tenantDelete,
  tenantsPerUnitGet,
  tenantsPerPropertyGet,
} from './actions';
import {
  resetState,
  selectTenant,
  resetStatusStates,
  selectSingleTenant,
  selectTenantsToUnit,
  selectTenantGetStatus,
  selectTenantPutStatus,
  selectTenantsGetStatus,
  selectTenantPostStatus,
  selectTenantDeleteStatus,
  selectTenantToUnitStatus,
  selectTenantsPropertyGetStatus,
} from './slice';

interface UseTenants {
  tenants: ApiTenant | undefined;
  singleTenant: Tenant | undefined;
  tenantGetStatus: ProcessingStatus;
  tenantPutStatus: ProcessingStatus;
  tenantsGetStatus: ProcessingStatus;
  tenantPostStatus: ProcessingStatus;
  tenantDeleteStatus: ProcessingStatus;
  tenantsToUnitGetStatus: ProcessingStatus;
  tenantsToUnit: ApiTenantToUnit | undefined;
  tenantsToPropertyGetStatus: ProcessingStatus;

  resetStatus: () => void;
  resetTenantStates: () => void;
  deleteTenant: (param: ParamsRole) => void;
  fetchTenants: (param: ParamsRole) => void;
  createTenant: (params: RegisterForm) => void;
  updateTenant: (params: TenantPayload) => void;
  fetchSingleTenant: (param: ParamsRole) => void;
  fetchTenantsToUnit: (param: ParamsRole) => void;
  fetchPropertyTenants: (param: ParamsRole) => void;
}

export function useTenants(): UseTenants {
  const dispatch = useDispatch();

  const tenants = useSelector(selectTenant);
  const singleTenant = useSelector(selectSingleTenant);
  const tenantsToUnit = useSelector(selectTenantsToUnit);
  const tenantGetStatus = useSelector(selectTenantGetStatus);
  const tenantPutStatus = useSelector(selectTenantPutStatus);
  const tenantsGetStatus = useSelector(selectTenantsGetStatus);
  const tenantPostStatus = useSelector(selectTenantPostStatus);
  const tenantDeleteStatus = useSelector(selectTenantDeleteStatus);
  const tenantsToPropertyGetStatus = useSelector(selectTenantToUnitStatus);
  const tenantsToUnitGetStatus = useSelector(selectTenantsPropertyGetStatus);

  const fetchTenants = useCallback(
    (param: ParamsRole) => {
      dispatch(tenantsGet(param));
    },
    [dispatch]
  );

  const resetTenantStates = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const resetStatus = useCallback(() => {
    dispatch(resetStatusStates());
  }, [dispatch]);

  const fetchTenantsToUnit = useCallback(
    (param: ParamsRole) => {
      dispatch(tenantsPerUnitGet(param));
    },
    [dispatch]
  );

  const fetchPropertyTenants = useCallback(
    (param: ParamsRole) => {
      dispatch(tenantsPerPropertyGet(param));
    },
    [dispatch]
  );

  const fetchSingleTenant = useCallback(
    (param: ParamsRole) => {
      dispatch(tenantGet(param));
    },
    [dispatch]
  );

  const deleteTenant = useCallback(
    (param: ParamsRole) => {
      dispatch(tenantDelete(param));
    },
    [dispatch]
  );

  const createTenant = useCallback(
    (params: RegisterForm) => {
      dispatch(tenantPost(params));
    },
    [dispatch]
  );

  const updateTenant = useCallback(
    (params: TenantPayload) => {
      dispatch(tenantPut(params));
    },
    [dispatch]
  );

  return {
    tenants,
    resetStatus,
    fetchTenants,
    singleTenant,
    createTenant,
    updateTenant,
    deleteTenant,
    tenantsToUnit,
    tenantGetStatus,
    tenantPutStatus,
    tenantsGetStatus,
    tenantPostStatus,
    fetchSingleTenant,
    resetTenantStates,
    fetchTenantsToUnit,
    tenantDeleteStatus,
    fetchPropertyTenants,
    tenantsToUnitGetStatus,
    tenantsToPropertyGetStatus,
  };
}
