import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

interface Props {
  estateName: string;
  estateOwner: string;
  estateLocation: string;
}

export function Property(props: Props): JSX.Element {
  const { estateLocation, estateName, estateOwner } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.propertyCardLeft}>
        <Typography className={classes.estateName} variant="subtitle2">
          {estateName}
        </Typography>
        <Typography className={classes.estateOwner} variant="subtitle2">
          {estateOwner}
        </Typography>
        <Typography className={classes.estateLocation} variant="subtitle2">
          {estateLocation}
        </Typography>
      </div>
      <div className={classes.propertyCardRight}>
        {/*<Typography className={classes.link} variant="subtitle2">*/}
        {/*  units*/}
        {/*</Typography>*/}
        <Typography className={classes.link} variant="subtitle2">
          Edit
        </Typography>
      </div>
    </div>
  );
}
