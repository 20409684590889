import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  callPut,
  callGet,
  callPost,
  callDelete,
  getHttpEndpoint,
} from '@real-estate-portal/utils';
import {
  User,
  ApiUser,
  ApiCompany,
  RespStatus,
  ParamsRole,
  AccountForm,
  CompanyForm,
  ApiTempAdmins,
  PasswordSettings,
  AccountStateForm,
} from '@real-estate-portal/interfaces';
import { Roles } from '@real-estate-portal/enums';

export const adminDelete = createAsyncThunk(
  'account/admin-delete',
  async (params: ParamsRole, thunkAPI) => {
    try {
      const { role, id } = params;
      const { data } = await callDelete<RespStatus>(
        getHttpEndpoint(role, `/${id}`).adminDelete ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const userGet = createAsyncThunk(
  'account/user',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callGet<User>(getHttpEndpoint(role).user);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const companiesGet = createAsyncThunk(
  'account/companies',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { data } = await callGet<ApiCompany>(
        getHttpEndpoint(param.role, param.params).companies ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const tempAdminsGet = createAsyncThunk(
  'account/temp-admins',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { data } = await callGet<ApiTempAdmins>(
        getHttpEndpoint(param.role, param.params).tempAdmins ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const companyPost = createAsyncThunk(
  'account/create-company',
  async (params: CompanyForm, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, CompanyForm>(
        getHttpEndpoint(params.role).registerCompany ?? '',
        params
      );

      if (!data || data === 0) {
        return thunkAPI.rejectWithValue('error');
      }

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const adminsGet = createAsyncThunk(
  'account/admins',
  async (param: ParamsRole, thunkAPI) => {
    try {
      const { data } = await callGet<ApiUser>(
        getHttpEndpoint(param.role, param.params).admins ?? ''
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const userPut = createAsyncThunk(
  'account/update-user',
  async (params: AccountForm, thunkAPI) => {
    try {
      const { data } = await callPut<RespStatus, AccountForm>(
        getHttpEndpoint(params.role).updateUser,
        params
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const changeAdminState = createAsyncThunk(
  'account/admin-change-state',
  async (params: AccountStateForm, thunkAPI) => {
    const { uuid, ...rest } = params;
    try {
      const { data } = await callPut<RespStatus, AccountStateForm>(
        getHttpEndpoint(rest.role, `/${uuid}`).changeAdminState ?? '',
        rest
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const passwordPut = createAsyncThunk(
  'account/update-password',
  async (params: PasswordSettings, thunkAPI) => {
    try {
      const { data } = await callPut<RespStatus, PasswordSettings>(
        getHttpEndpoint(params.role).passwordUpdate,
        params
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);

export const logoutPost = createAsyncThunk(
  'account/logout',
  async (role: Roles, thunkAPI) => {
    try {
      const { data } = await callPost<RespStatus, null>(
        getHttpEndpoint(role).logout,
        null
      );

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue({ errors: [e] });
    }
  }
);
