import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ParamsRole,
  Transaction,
  LipaNaMpesa,
  ApiTransaction,
  LipaNaMpesaForm,
  TransactionPayload,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus, Roles } from '@real-estate-portal/enums';

import {
  transactionPut,
  transactionsGet,
  transactionPost,
  lipaNaMpesaPost,
  transactionGetByUser,
  transactionsGetByUnit,
} from './actions';
import {
  resetStatus,
  resetDataStates,
  selectLipaNaMpesa,
  selectTransaction,
  selectTransactions,
  selectTransactionGetStatus,
  selectTransactionPutStatus,
  selectTransactionsGetStatus,
  selectTransactionPostStatus,
  selectLipaNaMpesaPostStatus,
} from './slice';

interface UseTransactions {
  lipaNaMpesa: LipaNaMpesa | undefined;
  transaction: Transaction | undefined;
  transactions: ApiTransaction | undefined;

  transactionGetStatus: ProcessingStatus;
  transactionPutStatus: ProcessingStatus;
  transactionsGetStatus: ProcessingStatus;
  transactionPostStatus: ProcessingStatus;
  lipaNaMpesaPostStatus: ProcessingStatus;

  resetStatusStates: () => void;
  resetTransactionsState: () => void;
  fetchTransactions: (role: Roles) => void;
  fetchUserTransactions: (param: ParamsRole) => void;
  fetchUnitTransactions: (param: ParamsRole) => void;
  triggerLipaNaMpesa: (payload: LipaNaMpesaForm) => void;
  updateTransaction: (payload: TransactionPayload) => void;
  createTransaction: (payload: TransactionPayload) => void;
}

export function useTransactions(): UseTransactions {
  const dispatch = useDispatch();

  const lipaNaMpesa = useSelector(selectLipaNaMpesa);
  const transaction = useSelector(selectTransaction);
  const transactions = useSelector(selectTransactions);
  const transactionPutStatus = useSelector(selectTransactionPutStatus);
  const transactionGetStatus = useSelector(selectTransactionGetStatus);
  const lipaNaMpesaPostStatus = useSelector(selectLipaNaMpesaPostStatus);
  const transactionsGetStatus = useSelector(selectTransactionsGetStatus);
  const transactionPostStatus = useSelector(selectTransactionPostStatus);

  const fetchTransactions = useCallback(
    (role: Roles) => {
      dispatch(transactionsGet(role));
    },
    [dispatch]
  );

  const resetTransactionsState = useCallback(() => {
    dispatch(resetDataStates());
  }, [dispatch]);

  const resetStatusStates = useCallback(() => {
    dispatch(resetStatus());
  }, [dispatch]);

  const fetchUnitTransactions = useCallback(
    (param: ParamsRole) => {
      dispatch(transactionsGetByUnit(param));
    },
    [dispatch]
  );

  const fetchUserTransactions = useCallback(
    (param: ParamsRole) => {
      dispatch(transactionGetByUser(param));
    },
    [dispatch]
  );

  const triggerLipaNaMpesa = useCallback(
    (payload: LipaNaMpesaForm) => {
      dispatch(lipaNaMpesaPost(payload));
    },
    [dispatch]
  );

  const createTransaction = useCallback(
    (payload: TransactionPayload) => {
      dispatch(transactionPost(payload));
    },
    [dispatch]
  );

  const updateTransaction = useCallback(
    (payload: TransactionPayload) => {
      dispatch(transactionPut(payload));
    },
    [dispatch]
  );

  return {
    transaction,
    transactions,
    lipaNaMpesa,
    createTransaction,
    fetchTransactions,
    resetStatusStates,
    updateTransaction,
    triggerLipaNaMpesa,
    transactionPutStatus,
    transactionGetStatus,
    lipaNaMpesaPostStatus,
    transactionsGetStatus,
    transactionPostStatus,
    fetchUnitTransactions,
    fetchUserTransactions,
    resetTransactionsState,
  };
}
