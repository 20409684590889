import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import AppsIcon from '@material-ui/icons/Apps';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { OptionType } from '@real-estate-portal/interfaces';

import { useStyles } from './styles';
import FormSelectField from '../../form/form-select/FormSelectField';

interface Props {
  cycleOption: OptionType;
  cycleOptions: OptionType[];

  onLogout: () => void;
  onCycleChange: (data: OptionType) => void;
}

export function AppNavTenant(props: Props) {
  const {
    cycleOption,
    cycleOptions,
    onLogout: handleLogout,
    onCycleChange: handleCycleChange,
  } = props;
  const classes = useStyles({ marginLeft: 0 });

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
          >
            <AppsIcon />
          </IconButton>
          <span className={classes.cycleInputWrapper}>
            <FormSelectField
              isTransparent
              name="cycle"
              value={cycleOption}
              options={cycleOptions}
              className={classes.selectField}
              onChange={handleCycleChange}
            />
          </span>

          <Typography
            variant="subtitle2"
            className={classes.btnText}
            onClick={handleLogout}
          >
            Sign out
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
