import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Documents,
  ParamsRole,
  DocumentsForm,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  selectDocuments,
  resetStatusStates,
  selectDocumentPostStatus,
  selectDocumentsGetStatus,
  selectDocumentDeleteStatus,
} from './slice';
import { documentsGet, documentPost, documentDelete } from './actions';

interface UseDocuments {
  documents: Documents[] | undefined;
  documentsGetStatus: ProcessingStatus;
  documentPostStatus: ProcessingStatus;
  documentDeleteStatus: ProcessingStatus;

  resetStatus: () => void;
  deleteDocument: (param: ParamsRole) => void;
  fetchDocuments: (param: ParamsRole) => void;
  createDocument: (payload: DocumentsForm) => void;
}

export function useDocuments(): UseDocuments {
  const dispatch = useDispatch();

  const documents = useSelector(selectDocuments);
  const documentPostStatus = useSelector(selectDocumentPostStatus);
  const documentsGetStatus = useSelector(selectDocumentsGetStatus);
  const documentDeleteStatus = useSelector(selectDocumentDeleteStatus);

  const resetStatus = useCallback(() => {
    dispatch(resetStatusStates());
  }, [dispatch]);

  const fetchDocuments = useCallback(
    (param: ParamsRole) => {
      dispatch(documentsGet(param));
    },
    [dispatch]
  );

  const createDocument = useCallback(
    (payload: DocumentsForm) => {
      dispatch(documentPost(payload));
    },
    [dispatch]
  );

  const deleteDocument = useCallback(
    (param: ParamsRole) => {
      dispatch(documentDelete(param));
    },
    [dispatch]
  );

  return {
    documents,
    resetStatus,
    deleteDocument,
    createDocument,
    fetchDocuments,
    documentPostStatus,
    documentsGetStatus,
    documentDeleteStatus,
  };
}
