import React, { useMemo } from 'react';
import { Page, Text, View, Image, Document } from '@react-pdf/renderer';

import { formatDigit, simplifyCycle } from '@real-estate-portal/utils';
import { OptionType, SumOption } from '@real-estate-portal/interfaces';

import styles from './styles';

interface Props {
  logo: string;
  reportType: string;
  totals: SumOption[];
  tenantInfo: OptionType[];
  cycle: string | undefined;
  companyInfo: OptionType[];
  children: React.ReactNode;
}

function PdfTemplate(props: Props): JSX.Element {
  const {
    logo,
    cycle,
    totals,
    children,
    tenantInfo,
    reportType,
    companyInfo,
  } = props;

  const tenant = useMemo((): OptionType[] => tenantInfo, [tenantInfo]);
  const company = useMemo((): OptionType[] => companyInfo, [companyInfo]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerContainer}>
          <View style={styles.section}>
            <Image src={logo} style={styles.logo} />
          </View>
          <View style={styles.section}>
            <View style={styles.headerWrapper}>
              {company &&
                company.map((detail, key) => (
                  <View style={styles.headerInnerWrapper} key={key}>
                    <Text style={styles.headerLabel}>{detail.label}</Text>
                    <Text style={styles.headerValue}>{detail.value}</Text>
                  </View>
                ))}
            </View>
            <View style={styles.headerWrapper}>
              {tenant &&
                tenant.map((detail, key) => (
                  <View style={styles.headerInnerWrapper} key={key}>
                    <Text style={styles.headerLabel}>{detail.label}</Text>
                    <Text style={styles.headerValue}>{detail.value}</Text>
                  </View>
                ))}
            </View>
          </View>
        </View>

        <View style={styles.dateWrapper}>
          <Text style={styles.cycle}>
            {reportType}: {cycle ? simplifyCycle(cycle) : '00-000'}
          </Text>
        </View>

        <View style={styles.table}>{children}</View>

        <View style={styles.statsWrapper}>
          {totals &&
            totals.map((total, key) => (
              <View style={styles.tableFooterWrapper} key={key}>
                <Text style={styles.sumTitle}>{total.label}</Text>
                <Text style={styles.sumValue}>
                  {formatDigit(total.value as number)}
                </Text>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
}

export default PdfTemplate;
