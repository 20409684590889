import React, { useCallback } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import {
  logout,
  getStoredData,
  isTokenExpired,
} from '@real-estate-portal/utils';
import { TOKEN_STORAGE_NAME } from '@real-estate-portal/constants';
import { RealEstatePaths } from '@real-estate-portal/enums';
import { Spinner } from '@real-estate-portal/ui';

function ProtectedRoute(props: RouteProps): JSX.Element {
  const history = useHistory();
  const token = getStoredData(TOKEN_STORAGE_NAME);

  const validateToken = useCallback(async () => {
    if (isTokenExpired()) {
      logout();
      history.push(RealEstatePaths.LOGIN);
    }
  }, [history]);

  React.useEffect(() => {
    validateToken().then((r) => r);
  }, [validateToken]);

  return token ? <Route {...props} /> : <Spinner />;
}

export default ProtectedRoute;
