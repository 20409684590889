import { createSlice } from '@reduxjs/toolkit';

import { ProcessingStatus } from '@real-estate-portal/enums';
import {
  DefaultSettings,
  EnvConfig,
  Settings,
} from '@real-estate-portal/interfaces';

import {
  settingGet,
  settingPut,
  settingPost,
  settingEnvPut,
  settingEnvGet,
} from './actions';

interface SettingsState {
  envSettings: EnvConfig;
  settings: Settings | undefined;

  defaultSettings: {
    cycle: undefined;
    property: undefined;
  };

  settingsPutStatus: ProcessingStatus;
  settingsGetStatus: ProcessingStatus;
  settingsPostStatus: ProcessingStatus;
  settingsEnvGetStatus: ProcessingStatus;
  settingsEnvPutStatus: ProcessingStatus;
}

export const initialState: SettingsState = {
  settings: undefined,
  envSettings: {} as EnvConfig,

  defaultSettings: {
    cycle: undefined,
    property: undefined,
  },

  settingsPutStatus: ProcessingStatus.IDLE,
  settingsGetStatus: ProcessingStatus.IDLE,
  settingsPostStatus: ProcessingStatus.IDLE,
  settingsEnvGetStatus: ProcessingStatus.IDLE,
  settingsEnvPutStatus: ProcessingStatus.IDLE,
};

interface SettingsSlice {
  settings: SettingsState;
}

export const settingsSlice = createSlice({
  initialState,
  reducers: {
    resetStateStatuses: (state) => {
      state.settingsPutStatus = ProcessingStatus.IDLE;
      state.settingsGetStatus = ProcessingStatus.IDLE;
      state.settingsPostStatus = ProcessingStatus.IDLE;
      state.settingsEnvGetStatus = ProcessingStatus.IDLE;
      state.settingsEnvPutStatus = ProcessingStatus.IDLE;
    },
    setDefaultSettings: (state, action) => {
      state.defaultSettings = action.payload;
    },
  },
  name: 'settings',
  extraReducers: (builder) => {
    builder.addCase(settingGet.pending, (state) => {
      state.settingsGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(settingGet.fulfilled, (state, action) => {
      state.settingsGetStatus = ProcessingStatus.SUCCEEDED;
      state.settings = action.payload;
    });
    builder.addCase(settingGet.rejected, (state) => {
      state.settingsGetStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(settingPost.pending, (state) => {
      state.settingsPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(settingPost.fulfilled, (state, action) => {
      state.settingsPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(settingPost.rejected, (state) => {
      state.settingsPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(settingPut.pending, (state) => {
      state.settingsPostStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(settingPut.fulfilled, (state, action) => {
      state.settingsPostStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(settingPut.rejected, (state) => {
      state.settingsPostStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(settingEnvPut.pending, (state) => {
      state.settingsEnvPutStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(settingEnvPut.fulfilled, (state, action) => {
      state.settingsEnvPutStatus = ProcessingStatus.SUCCEEDED;
    });
    builder.addCase(settingEnvPut.rejected, (state) => {
      state.settingsEnvPutStatus = ProcessingStatus.FAILED;
    });

    builder.addCase(settingEnvGet.pending, (state) => {
      state.settingsEnvGetStatus = ProcessingStatus.PENDING;
    });
    builder.addCase(settingEnvGet.fulfilled, (state, action) => {
      state.settingsEnvGetStatus = ProcessingStatus.SUCCEEDED;
      state.envSettings = action.payload;
    });
    builder.addCase(settingEnvGet.rejected, (state) => {
      state.settingsEnvGetStatus = ProcessingStatus.FAILED;
    });
  },
});

export const settingsReducer = settingsSlice.reducer;
export const { resetStateStatuses, setDefaultSettings } = settingsSlice.actions;

export const selectGetStatus = (state: SettingsSlice): ProcessingStatus =>
  state.settings.settingsGetStatus;

export const selectEnvConfig = (state: SettingsSlice): EnvConfig =>
  state.settings.envSettings;

export const selectEnvPutStatus = (state: SettingsSlice): ProcessingStatus =>
  state.settings.settingsEnvPutStatus;

export const selectEnvGetStatus = (state: SettingsSlice): ProcessingStatus =>
  state.settings.settingsEnvGetStatus;

export const selectPostStatus = (state: SettingsSlice): ProcessingStatus =>
  state.settings.settingsPostStatus;

export const selectPutStatus = (state: SettingsSlice): ProcessingStatus =>
  state.settings.settingsPutStatus;

export const selectSettings = (state: SettingsSlice): Settings | undefined =>
  state.settings.settings;

export const selectDefaultSettings = (state: SettingsSlice): DefaultSettings =>
  state.settings.defaultSettings;
