import * as forge from 'node-forge';

export function encryptPaymentData(key: string, data: string) {
  const encode = 'utf-8' as forge.Encoding;
  const algo = '3DES-ECB';
  const cipher = forge.cipher.createCipher(algo, forge.util.createBuffer(key));

  cipher.start({ iv: '' });
  cipher.update(forge.util.createBuffer(data, encode));
  cipher.finish();
  const encrypted = cipher.output;

  return forge.util.encode64(encrypted.getBytes());
}
