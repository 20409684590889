import React from 'react';
import cn from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import { useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Roles } from '@real-estate-portal/enums';
import { SideMenu } from '@real-estate-portal/interfaces';
import { primaryColors } from '@real-estate-portal/constants';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  btnHover: {
    '&:hover': {
      color: primaryColors.white,
    },
  },
  btnActive: {
    color: primaryColors.white,
    backgroundColor: primaryColors.blue,
  },
  navIcon: {
    color: primaryColors.white,
  },
}));

interface Props {
  role: Roles;
  menuItems: SideMenu;
  onRedirect: (pathName: string) => void;
}

export function DrawerNav(props: Props) {
  const classes = useStyles();
  const location = useLocation();
  const { role, menuItems, onRedirect: handleRedirect } = props;
  const pathArray = location.pathname.split('/') as string[];

  const admin = (): JSX.Element => {
    return (
      <>
        {menuItems &&
          menuItems.admin.map((item, key) => {
            const { path: pathItems, pathName, icon: ItemIcon } = item;
            const paths = pathItems.split('/') as string[];
            const path = paths[2];

            return (
              <ListItem
                button
                key={key}
                className={cn(classes.btnHover, {
                  [classes.btnActive]: pathArray.includes(path),
                })}
                onClick={(): void => handleRedirect(path)}
              >
                <ListItemIcon>
                  <ItemIcon
                    className={cn(classes.btnHover, {
                      [classes.navIcon]: pathArray.includes(path),
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={pathName} />
              </ListItem>
            );
          })}
        <Divider />
        <List>
          {menuItems &&
            menuItems.adminExtra.map((item, key) => {
              const { path: pathItems, pathName, icon: ItemIcon } = item;
              const paths = pathItems.split('/') as string[];
              const path = paths[2];

              return (
                <ListItem
                  button
                  key={key}
                  className={cn(classes.btnHover, {
                    [classes.btnActive]: location.pathname === path,
                  })}
                  onClick={(): void => handleRedirect(path)}
                >
                  <ListItemIcon>
                    <ItemIcon
                      className={cn(classes.btnHover, {
                        [classes.navIcon]: location.pathname === path,
                      })}
                    />
                  </ListItemIcon>
                  <ListItemText primary={pathName} />
                </ListItem>
              );
            })}
        </List>
      </>
    );
  };

  const tenant = (): JSX.Element => {
    return (
      <>
        {menuItems &&
          menuItems.tenant.map((item, key) => {
            const { path, pathName, icon: ItemIcon } = item;

            return (
              <ListItem
                button
                key={key}
                className={cn(classes.btnHover, {
                  [classes.btnActive]: location.pathname === path,
                })}
                onClick={(): void => handleRedirect(path)}
              >
                <ListItemIcon>
                  <ItemIcon />
                </ListItemIcon>
                <ListItemText primary={pathName} />
              </ListItem>
            );
          })}
        {/*<Divider />*/}
        <List>
          {menuItems &&
            menuItems.tenantExtra.map((item, key) => {
              const { path, pathName, icon: ItemIcon } = item;

              return (
                <ListItem
                  button
                  key={key}
                  className={cn(classes.btnHover, {
                    [classes.btnActive]: location.pathname === path,
                  })}
                  onClick={(): void => handleRedirect(path)}
                >
                  <ListItemIcon>
                    <ItemIcon />
                  </ListItemIcon>
                  <ListItemText primary={pathName} />
                </ListItem>
              );
            })}
        </List>
      </>
    );
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {role === Roles.ADMIN && admin()}
        {role === Roles.TENANT && tenant()}
      </List>
    </Drawer>
  );
}
