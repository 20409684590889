import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import AppsIcon from '@material-ui/icons/Apps';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './styles';

interface Props {
  onLogout: () => void;
}

export function AppNavSuperAdmin(props: Props) {
  const { onLogout: handleLogout } = props;
  const classes = useStyles({ marginLeft: 0 });

  const handleUserLogout = (): void => {
    handleLogout();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
          >
            <AppsIcon />
          </IconButton>

          <Typography
            variant="subtitle2"
            className={classes.btnText}
            onClick={handleUserLogout}
          >
            Sign out
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
