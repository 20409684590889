import { RealEstatePaths } from '@real-estate-portal/enums';

function getBaseName(): string {
  const pathNames = ['/gelin', '/oryx', '/almond', '/vex'];
  const currentPathName = window.location.pathname.split('/')[1];
  const prefix = `/${currentPathName}`;

  if (pathNames.includes(prefix)) {
    return prefix;
  }

  return '';
}

interface GetPaths {
  logs: string;
  login: string;
  search: string;
  tenant: string;
  tenants: string;
  charges: string;
  baseUrl: string;
  property: string;
  services: string;
  payments: string;
  settings: string;
  register: string;
  dashboard: string;
  properties: string;
  transactions: string;
  notifications: string;
  property_unit: string;
  reset_password: string;
  dashboard_tenant: string;
  property_new_onboarding: string;
}

export function getPaths(): GetPaths {
  const logs = `${getBaseName()}${RealEstatePaths.LOGS}`;
  const search = `${getBaseName()}${RealEstatePaths.SEARCH}`;
  const charges = `${getBaseName()}${RealEstatePaths.CHARGES}`;
  const tenants = `${getBaseName()}${RealEstatePaths.TENANTS}`;
  const payments = `${getBaseName()}${RealEstatePaths.PAYMENTS}`;
  const settings = `${getBaseName()}${RealEstatePaths.SETTINGS}`;
  const services = `${getBaseName()}${RealEstatePaths.SERVICES}`;
  const dashboard = `${getBaseName()}${RealEstatePaths.DASHBOARD}`;
  const properties = `${getBaseName()}${RealEstatePaths.PROPERTIES}`;

  const dashboard_tenant = `${getBaseName()}${
    RealEstatePaths.DASHBOARD_TENANT
  }`;
  const property_new_onboarding = `${getBaseName()}${
    RealEstatePaths.PROPERTY_NEW_ONBOARDING
  }`;

  const login = `${getBaseName()}${RealEstatePaths.LOGIN}`;
  const tenant = `${getBaseName()}${RealEstatePaths.TENANT}`;
  const baseUrl = `${getBaseName()}${RealEstatePaths.BASE_URL}`;
  const register = `${getBaseName()}${RealEstatePaths.REGISTER}`;
  const property = `${getBaseName()}${RealEstatePaths.PROPERTY}`;
  const transactions = `${getBaseName()}${RealEstatePaths.TRANSACTIONS}`;
  const notifications = `${getBaseName()}${RealEstatePaths.NOTIFICATIONS}`;
  const property_unit = `${getBaseName()}${RealEstatePaths.PROPERTY_UNIT}`;
  const reset_password = `${getBaseName()}${RealEstatePaths.RESET_PASSWORD}`;

  return {
    logs,
    login,
    tenant,
    search,
    tenants,
    baseUrl,
    charges,
    register,
    settings,
    payments,
    property,
    services,
    dashboard,
    properties,
    transactions,
    notifications,
    property_unit,
    reset_password,
    dashboard_tenant,
    property_new_onboarding,
  };
}
