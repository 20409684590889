import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ApiBill,
  BillForm,
  ParamsRole,
  PropertyBills,
  UnitAssignBill,
} from '@real-estate-portal/interfaces';
import { ProcessingStatus } from '@real-estate-portal/enums';

import {
  billPut,
  billGet,
  billPost,
  billsGet,
  billAssign,
  billDelete,
} from './actions';
import {
  selectBills,
  resetStatusStates,
  selectBillPutStatus,
  selectPropertyBills,
  selectBillPostStatus,
  selectBillsGetStatus,
  selectBillAssignStatus,
  selectBillDeleteStatus,
} from './slice';

interface UseBills {
  bills: ApiBill | undefined;
  propertyBills: PropertyBills[];
  billPutStatus: ProcessingStatus;
  billPostStatus: ProcessingStatus;
  billsGetStatus: ProcessingStatus;
  billAssignStatus: ProcessingStatus;
  billDeleteStatus: ProcessingStatus;

  resetStatus: () => void;
  createBill: (payload: BillForm) => void;
  updateBill: (payload: BillForm) => void;
  deleteBill: (param: ParamsRole) => void;
  fetchBills: (params: ParamsRole) => void;
  assignBill: (params: UnitAssignBill) => void;
  fetchPropertyBills: (param: ParamsRole) => void;
}

export function useBills(): UseBills {
  const dispatch = useDispatch();

  const bills = useSelector(selectBills);
  const billPutStatus = useSelector(selectBillPutStatus);
  const propertyBills = useSelector(selectPropertyBills);
  const billPostStatus = useSelector(selectBillPostStatus);
  const billsGetStatus = useSelector(selectBillsGetStatus);
  const billDeleteStatus = useSelector(selectBillDeleteStatus);
  const billAssignStatus = useSelector(selectBillAssignStatus);

  const resetStatus = useCallback(() => {
    dispatch(resetStatusStates());
  }, [dispatch]);

  const fetchBills = useCallback(
    (param: ParamsRole) => {
      dispatch(billsGet(param));
    },
    [dispatch]
  );

  const fetchPropertyBills = useCallback(
    (param: ParamsRole) => {
      dispatch(billGet(param));
    },
    [dispatch]
  );

  const assignBill = useCallback(
    (param: UnitAssignBill) => {
      dispatch(billAssign(param));
    },
    [dispatch]
  );

  const createBill = useCallback(
    (payload: BillForm) => {
      dispatch(billPost(payload));
    },
    [dispatch]
  );

  const updateBill = useCallback(
    (payload: BillForm) => {
      dispatch(billPut(payload));
    },
    [dispatch]
  );

  const deleteBill = useCallback(
    (param: ParamsRole) => {
      dispatch(billDelete(param));
    },
    [dispatch]
  );

  return {
    bills,
    deleteBill,
    assignBill,
    fetchBills,
    createBill,
    updateBill,
    resetStatus,
    billPutStatus,
    propertyBills,
    billPostStatus,
    billsGetStatus,
    billDeleteStatus,
    billAssignStatus,
    fetchPropertyBills,
  };
}
